import React from 'react';
import { Form } from 'react-bootstrap';
import QuestionText from './QuestionText';
import TooltipComponent from './TooltipComponent';

const InputText = ({ question, handleAssessmentChange, handleValue }) => (
  <QuestionText question={question}>
    <div key={`default-input-${question.id}`} className='mb-3'>
      {question.answers.map((answer) => (
        <div key={answer.id} className='d-flex align-items-center mb-3 row'>
          <div className='col d-flex align-items-center mb-3'>
            <Form.Group className='mb-0'>
              <Form.Control
                type='text'
                id={`${answer.id}`}
                name={`${question.id}`}
                onChange={(e) => handleAssessmentChange(e, question.id, answer.id, question.type)}
                value={handleValue(question.id, answer.id)}
              />
            </Form.Group>
            {answer.description ? <TooltipComponent data={answer.description} /> : null}
          </div>
        </div>
      ))}
    </div>
  </QuestionText>
);

export default InputText;
