import React, { useState, useEffect, Suspense } from 'react';
import './styles/main.scss';

//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';

//core
import 'primereact/resources/primereact.min.css';

import {
  BrowserRouter,
  Route,
  // Router,
  Switch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import {
  Scenari,
  Scenario,
  ScenarioDashboard,
  Login,
  EconomicKPI,
  AnalisiImpatto,
  BibliografiaEssenziale,
  PubblicazioniOE,
  ImpactFinance,
  Platform,
  Method,
  SDG,
  WizardStep,
  WizardStepCompact,
  LayerStepCompact,
  Country,
  Modelling,
  Administration,
  //Ico
} from '@pages';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import { Footprint, Keyindicators, Structure } from '@pages/countryprofile';
import { Datasets, ExpenseItems, Classes, Kpi, InterventionTypes } from '@pages/modellingpages';
import {
  UsersManagement,
  OrganizationsManagement,
  TextsManagement,
} from '@pages/administrationpages';

import { LayoutBase, LayoutSimple, LayoutSideMenu } from './layouts';

import { logout, getTextTranslations, checkTokenValidity } from '@lib/api';

import client from '@lib/graphql';
import { ApolloProvider } from '@apollo/client';
import Opengallery from 'pages/opengallery';
// import Dataset from 'pages/modellingpages/components/dataset';
import { NewScenarioImpact } from '@pages/newScenario';
import { IncomeTaxes, Unemployment, NewDataset } from 'pages/modellingpages/index';
import { CountryProfileHome } from 'pages/newcountryprofile/index';
// import { UserProvider } from 'components/UserContext';
import Monitoring from 'pages/monitoring/index';
import CorporateEsgPage from 'pages/corporateesgpage/CorporateEsgPage';
import CorporateClassPage from 'pages/corporateclasspage/corporateClassPage';
// import { fetchData } from '@lib/helpers/index';

//import { LayerStepCompact } from 'pages/index';

const SpinnerLoading = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    }}>
    <Spinner animation='border' role='status' />
  </div>
);

function App() {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const translations = await getTextTranslations();
        localStorage.setItem('translations', JSON.stringify(translations));
      } catch (error) {
        console.error('Error fetching translations:', error);
      }
    };
    // getTextTranslations().then((retrievedTranslations) => {
    //   localStorage.setItem('translations', JSON.stringify(retrievedTranslations));
    // });
    fetchData();
  }, []);

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    const externalyticsLanguage = localStorage.getItem('externalyticsLanguage');

    if (!externalyticsLanguage) {
      let languageToSet = 'en';

      if (['it', 'it-IT'].includes(userLanguage)) {
        languageToSet = 'it';
      } else if (['es', 'es-ES'].includes(userLanguage)) {
        languageToSet = 'es';
      }

      localStorage.setItem('externalyticsLanguage', languageToSet);
    }
  }, []);

  return (
    <Suspense fallback={<SpinnerLoading />}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Switch>
            <Route exact path='/' render={() => <Redirect to='/scenari/newscenariosimpact' />} />
            {/* <AuthenticatedRoute path="/" exact component={() => <Home />} layout={LayoutBase} /> */}
            {/* <UserProvider> */}
            {/* <AuthenticatedRoute
              path='/scenari'
              exact
              component={() => <Scenari />}
              layout={LayoutSideMenu}
              role={3}
              org
            /> */}

            {/* <AuthenticatedRoute path="/dashboard" exact component={() => <Scenario />}  layout={LayoutBase} /> */}
            <AuthenticatedRoute
              path='/scenari/:idScenario/dashboard'
              component={(props) => <Scenario {...props} />}
              layout={LayoutSideMenu}
              role={3}
              org
            />
            <AuthenticatedRoute
              path='/scenario/:idScenario/monitoring'
              component={(props) => <Monitoring {...props} />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/scenario/:idScenario/dashboard/variant/:idVariante'
              component={(props) => <ScenarioDashboard {...props} />}
              layout={LayoutSideMenu}
            />
            {/* <AuthenticatedRoute path="/scenari/:idScenario/comparators" component={() => <EconomicKPI />} layout={LayoutSideMenu} /> */}
            <AuthenticatedRoute
              path='/scenari/newscenariosimpact'
              component={() => <NewScenarioImpact />}
              layout={LayoutSideMenu}
            />
            {/* <AuthenticatedRoute
              path='/scenari/comparators'
              component={() => <EconomicKPI />}
              layout={LayoutSideMenu}
              org
            /> */}
            <AuthenticatedRoute
              path='/scenari/:idScenario/sdg'
              component={() => <SDG />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/about/analisi-impatto'
              component={() => <AnalisiImpatto />}
              layout={LayoutBase}
            />
            <AuthenticatedRoute
              path='/about/analisi-di-impatto'
              component={() => <ImpactFinance />}
              layout={LayoutBase}
            />
            <AuthenticatedRoute
              path='/about/piattaforma'
              component={() => <Platform />}
              layout={LayoutBase}
            />
            <AuthenticatedRoute
              path='/about/metodologia'
              component={() => <Method />}
              layout={LayoutBase}
            />
            <AuthenticatedRoute
              path='/bibliografia-essenziale'
              component={() => <BibliografiaEssenziale />}
              layout={LayoutBase}
            />
            <AuthenticatedRoute
              path='/pubblicazioni-OE'
              component={() => <PubblicazioniOE />}
              layout={LayoutBase}
            />
            <AuthenticatedRoute
              path='/country'
              component={() => <Country />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/opengallery'
              component={() => <Opengallery />}
              layout={LayoutSideMenu}
              role={1}
            />
            <AuthenticatedRoute
              path='/countryprofile'
              component={() => <CountryProfileHome />}
              layout={LayoutBase}
            />
            <AuthenticatedRoute
              path='/countryprofile/components/footprint'
              component={() => <Footprint />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/countryprofile/components/structure'
              component={() => <Structure />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/countryprofile/components/key-indicators'
              component={() => <Keyindicators />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/scenariosetup/:idScenario'
              component={() => <WizardStep />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/scenariosetup'
              component={() => <WizardStep />}
              layout={LayoutSideMenu}
            />
            <AuthenticatedRoute
              path='/scenariowizard/:idScenario/:idVariante?'
              component={() => <WizardStepCompact />}
              layout={LayoutSideMenu}
              role={3}
            />
            <AuthenticatedRoute
              path='/scenariowizard'
              component={() => <WizardStepCompact />}
              layout={LayoutSideMenu}
              role={3}
            />
            <AuthenticatedRoute
              path='/scenariowizard/:idScenario/:idVariante/?step=build'
              component={() => <LayerStepCompact />}
              layout={LayoutSideMenu}
              role={3}
            />
            {/* <AuthenticatedRoute path="/icone" component={() => <Ico />} layout={LayoutBase} /> */}
            <AuthenticatedRoute
              path='/modelling'
              exact
              component={() => <Modelling />}
              layout={LayoutSideMenu}
              role={2}
            />
            <AuthenticatedRoute
              path='/modelling/datasets'
              component={() => <Datasets />}
              layout={LayoutSideMenu}
              role={2}
            />
            {/* <AuthenticatedRoute
              path='/modelling/dataset/:param?'
              component={(match) => {
                const { param } = match.match.params;
                if (param && (param === 'new' || typeof +param === 'number')) {
                  return <NewDataset />;
                } else {
                  return <Dataset />;
                }
              }}
              layout={LayoutSideMenu}
              role={2}
            /> */}
            <AuthenticatedRoute
              path='/modelling/expenseitems'
              component={() => <ExpenseItems />}
              layout={LayoutSideMenu}
              role={2}
            />
            <AuthenticatedRoute
              path='/modelling/classes'
              component={() => <Classes />}
              layout={LayoutSideMenu}
              role={2}
            />
            <AuthenticatedRoute
              path='/modelling/corporateclasses'
              component={() => <CorporateClassPage />}
              layout={LayoutSideMenu}
              role={2}
            />
            <AuthenticatedRoute
              path='/modelling/dataset'
              component={() => <NewDataset />}
              layout={LayoutSideMenu}
              role={2}
            />
            <AuthenticatedRoute
              path='/modelling/kpi'
              exact
              component={() => <Kpi />}
              layout={LayoutSideMenu}
              role={2}
            />
            <AuthenticatedRoute
              path='/modelling/income-taxes'
              exact
              component={() => <IncomeTaxes />}
              layout={LayoutSideMenu}
              role={2}
            />
            <AuthenticatedRoute
              path='/modelling/unemployment'
              exact
              component={() => <Unemployment />}
              layout={LayoutSideMenu}
              role={2}
            />
            <AuthenticatedRoute
              path='/modelling/interventiontypes'
              exact
              component={() => <InterventionTypes />}
              layout={LayoutSideMenu}
              role={2}
            />
            {/* <AuthenticatedRoute
              path='/modelling/newscenarios'
              component={() => <NewScenarios />}
              layout={LayoutSideMenu}
            /> */}
            <AuthenticatedRoute
              path='/administration'
              exact
              component={() => <Administration />}
              layout={LayoutSideMenu}
              role={1}
            />
            <AuthenticatedRoute
              path='/corporateesg/:company?/:companyId?/:analysis?/:analysisId?/:section?'
              exact
              component={() => <CorporateEsgPage />}
              layout={LayoutBase}
              role={3}
            />
            <AuthenticatedRoute
              path='/administration/users'
              component={() => <UsersManagement />}
              layout={LayoutSideMenu}
              role={1}
            />
            <AuthenticatedRoute
              path='/administration/organizations'
              component={() => <OrganizationsManagement />}
              layout={LayoutSideMenu}
              role={1}
            />
            <AuthenticatedRoute
              path='/administration/texts/:translationCode?'
              component={(props) => <TextsManagement {...props} />}
              layout={LayoutSideMenu}
              role={1}
            />

            <UnauthenticatedRoute
              path='/login'
              exact
              component={() => <Login />}
              layout={LayoutSimple}
            />
            <LogoutRoute path='/logout' exact />
            {/* </UserProvider> */}
          </Switch>
        </BrowserRouter>
      </ApolloProvider>
    </Suspense>
  );
}

const AuthenticatedRoute = ({ component: Component, layout: Layout, role, ...rest }) => {
  const [auth, setAuth] = useState(null);
  const [userRole, setUserRole] = useState({ role: 1, organization: 1 });

  // NEW LOGIN
  const history = useHistory(); // Get the history object

  useEffect(() => {
    const fetchData = async () => {
      try {
        const isTokenValid = await checkTokenValidity();

        // const result = await isTokenValid.json();

        if (isTokenValid.success) {
          setAuth(200);
        } else {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          history.push('/login', { referrer: rest.location.pathname });
        }
      } catch (error) {
        console.error('Error checking token:', error);
        // Handle the error as needed, for example, redirect to an error page
      }
    };

    fetchData();
  }, [history, rest.location.pathname]);

  useEffect(() => {
    const fetchData = () => {
      const authResult = JSON.parse(localStorage.getItem('user'));
      if (authResult?.id) {
        // setAuth(200);
        setUserRole({
          role: +authResult.role,
          organization: +authResult.idOrganization,
        });
      } else {
        setAuth(401);
      }
    };
    fetchData();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth) return null;
        if (auth !== 200)
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { referrer: rest.location.pathname },
              }}
            />
          );
        if (role && userRole.role > role) {
          return (
            <Redirect
              to={{
                pathname: '/scenari/newscenariosimpact',
                state: { referrer: rest.location.pathname },
              }}
            />
          );
        }

        // if (
        //   rest.path === '/scenari' &&
        //   ((parseInt(userRole.role) <= 3 &&
        //     (parseInt(userRole.organization) === 14 || parseInt(userRole.organization) === 1)) ||
        //     parseInt(userRole.role) === 1)
        // )
        //   return (
        //     <Layout {...props}>
        //       <Component {...props} />
        //     </Layout>
        //   );
        if (rest.path === '/scenari/newscenariosimpact' && parseInt(userRole.organization) === 14)
          return (
            <Redirect
              to={{
                pathname: '/scenari',
                state: { referrer: rest.location.pathname },
              }}
            />
          );
        if (rest.org && userRole.organization !== 1 && userRole.organization !== 14)
          return (
            <Redirect
              to={{
                pathname: '/scenari/newscenariosimpact',
                state: { referrer: rest.location.pathname },
              }}
            />
          );
        return (
          <Layout {...props}>
            <Component {...props} auth={auth} />
          </Layout>
        );
      }}
    />
  );
};

const UnauthenticatedRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

const LogoutRoute = ({ component: Component, layout: Layout, ...rest }) => {
  logout();
  return <Redirect to='/login' />;
};

export default App;
