import React from 'react';
import InputPercentage from './InputPercentage';
import InputText from './InputText';
import InputNumber from './InputNumber';
import InputRadio from './InputRadio';
import InputCheckbox from './InputCheckbox';
import FadeInOutComponent from './FadeInComponent';

const componentsMap = {
  'input-perc': InputPercentage,
  'input-text': InputText,
  'input-num': InputNumber,
  radio: InputRadio,
  checkbox: InputCheckbox,
};

const QuestionItem = ({
  question,
  handleAssessmentChange,
  isVisible,
  handleValue,
  handleChecked,
}) => {
  const Component = componentsMap[question.type];

  if (!Component) return null;

  return (
    <FadeInOutComponent isVisible={isVisible}>
      <Component
        question={question}
        handleAssessmentChange={handleAssessmentChange}
        handleValue={handleValue}
        handleChecked={handleChecked}
      />
    </FadeInOutComponent>
  );
};

export default QuestionItem;
