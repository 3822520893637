import React, { Fragment, useState, useEffect } from 'react';
import { Trans } from '@lib/i18n';
import { useTranslation } from 'react-i18next';

import StatusBar from 'components/wizard/StatusBar';
import {
  getRegionKpiByScenario,
  getRegionKpiByScenarioDownload,
  postRegionKpiByScenarioUpload,
} from '@lib/api/index';
import { Button, OverlayTrigger, Row, Col, Tooltip, Spinner, Alert } from 'react-bootstrap';
import { Lens, IcoDownloadFile, IcoUploadFile } from '@icons';
import ModalDetails from 'components/wizard/stepKpi/ModalDetails';
import KpiTable from '../stepKpi/KpiTable';
import KpiFormUpload from '../stepKpi/KpiFormUpload';

const StepSubKpi = ({ cardTitle = <Trans code='A0675' />, scenarioId, variantId }) => {
  // State for status bar
  const [showStatusBar, setShowStatusBar] = useState(false);
  const [statusBarMessage, setStatusBarMessage] = useState('');
  const [statusBarType, setStatusBarType] = useState('');

  // State for data
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData] = useState({});

  // State for modal
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState({});

  const [modalUploadShow, setModalUploadShow] = useState(false);
  const [modalUploadTitle, setModalUploadTitle] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  // Translations
  const { t } = useTranslation();

  useEffect(() => {
    if (!scenarioId && !variantId) return;
    const fetchData = () => {
      setLoading(true);
      getRegionKpiByScenario(scenarioId, variantId)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
          setRefresh(false);
        });
    };
    fetchData();
  }, [scenarioId, variantId, refresh]);

  const yearsAvailable = [];
  if (!loading) {
    Object.values(data).forEach((region) => {
      Object.keys(region.values).forEach((year) => {
        if (!yearsAvailable.includes(year)) {
          yearsAvailable.push(year);
        }
      });
    });

    // Sort years
    yearsAvailable.sort();
  }

  const handleStatusBar = (status, message, autoHide = false) => {
    setShowStatusBar(true);
    setStatusBarType(status);
    setStatusBarMessage(message);
    if (autoHide) {
      setTimeout(() => {
        setShowStatusBar(false);
      }, 5000);
    }
  };

  const handleFileSelect = (file) => {
    setSelectedFile(file);
    if (file) {
      setShowAlert(false);
    }
  };

  const tooltip = (code) => (
    <Tooltip>
      <Trans code={code} />
    </Tooltip>
  );

  const handleDownload = async (regionCode) => {
    setLoadingButton(true);
    handleStatusBar('elaborating', false, false);

    const downloadResponse = await getRegionKpiByScenarioDownload(
      scenarioId,
      variantId,
      regionCode
    );

    if (downloadResponse.ok === true) {
      handleStatusBar('success', <Trans code='A0657' />, true);
      const blob = await downloadResponse.blob();

      const file = new File([blob], regionCode);
      var fileUrl = window.URL.createObjectURL(file);
      var a = document.createElement('a');
      a.href = fileUrl;
      a.download = `KPI-scenario-${scenarioId}-variant-${variantId}-${regionCode}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      setLoadingButton(false);
    } else if (!downloadResponse.ok || downloadResponse.status !== 200) {
      handleStatusBar('error', `Error`, false);
      setLoadingButton(false);
    }
  };

  const handleUploadClick = async () => {
    if (!selectedFile) {
      setShowAlert(true);
      return;
    }
    setLoadingButton(true);
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('id', scenarioId);
    try {
      closeModal();
      handleStatusBar('elaborating', false, false);
      postRegionKpiByScenarioUpload(formData, scenarioId, variantId)
        .then((res) => {
          if (res.status) {
            handleStatusBar('success', <Trans code='A0654' />, true);
            setRefresh(true);
            setLoadingButton(false);
          } else {
            handleStatusBar('error', res.message, true);
            setLoadingButton(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingButton(false);
          handleStatusBar('error', <Trans code='A0580' />, true);
        });
      setSelectedFile(null);
    } catch (error) {
      setLoading(false);
      console.log(error);
      setSelectedFile(null);
      handleStatusBar('error', <Trans code='A0580' />, true);
    }
  };

  const openModalUpload = (content) => {
    const prefix = JSON.parse(t('A0762')).label ?? '';
    setModalUploadTitle(prefix + ' - ' + content.regionName);
    setModalUploadShow(true);
  };

  const openModal = (content) => {
    const prefix = JSON.parse(t('A0675')).label ?? '';
    setModalTitle(prefix + ' - ' + content.regionName);
    setModalContent(content);
    setModalShow(true);
  };

  const closeModal = () => {
    setModalTitle('');
    setModalShow(false);
    setModalContent({});
    setModalUploadShow(false);
    setModalUploadTitle('');
    setSelectedFile(null);
    setShowAlert(false);
  };

  const formatNumber = (number) => {
    return Number(number).toLocaleString('it-IT', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className='simple-box mb-5 fade-in-div'>
      <Row className='mb-1'>
        <Col lg={12} className='form-login'>
          <h5 className='title-simple-box margin-title-box'>{cardTitle}</h5>
        </Col>
        <Col>
          {showStatusBar && <StatusBar status={statusBarType} message={statusBarMessage} />}
        </Col>
      </Row>
      {loading ? (
        <Spinner animation='border' />
      ) : (
        <div className='kpy-data-summary-container'>
          <table className='mb-4 kpi-data-summary-table'>
            <thead></thead>
            <tbody className='text-right'>
              <tr className='totals-row'>
                <td className='text-left'>
                  <strong>
                    <Trans code='A0173' />
                  </strong>
                </td>
                <td className='kpi-data-summary-tbody-empty'></td>
                {/* {yearsAvailable.map((year, index) => (
                  <td key={'thread-cell-kpi-region-' + index}>
                    <strong>{year}</strong>
                  </td>
                ))} */}
              </tr>
              {Object.keys(data).map((region, index) => {
                return (
                  <tr key={'tbody-row-kpi-region-' + index}>
                    <td className='pl-2'>
                      <div
                        key={'tbody-region-cell-kpi-region-' + index}
                        className='gap-3 cell-display-options'>
                        {data[region].regionName}
                      </div>
                    </td>
                    <td className='pl-2'>
                      <div>
                        <Fragment>
                          <div
                            id={`action-${index}`}
                            style={{
                              marginTop: '0.3rem',
                            }}>
                            <OverlayTrigger placement='top' overlay={tooltip('A0583')}>
                              <Button
                                {...(loadingButton && { disabled: true })}
                                onClick={() => {
                                  handleDownload(region);
                                }}
                                variant='link'
                                className='economic-data-action-buttons'>
                                <IcoDownloadFile className='second-color ico-small download-upload mr-3' />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement='top' overlay={tooltip('A0584')}>
                              <Button
                                onClick={() => {
                                  openModalUpload(data[region]);
                                }}
                                {...(loadingButton && { disabled: true })}
                                variant='link'
                                className='economic-data-action-buttons'>
                                <IcoUploadFile
                                  role='button'
                                  className='second-color ico-small download-upload mr-3'
                                />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement='top' overlay={tooltip('A0585')}>
                              <Button
                                variant='link'
                                className='economic-data-action-buttons'
                                onClick={() => openModal(data[region])}>
                                <Lens className='ico-small lens-bm green-lens' />
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </Fragment>
                      </div>
                    </td>
                    {/* {yearsAvailable.map((year, index) => (
                      <td className='pr-0' key={'tbody-cell-kpi-region-' + index}>
                        {formatNumber(data[region].values[year] || 0)}
                      </td>
                    ))} */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <ModalDetails titleModal={modalTitle} show={modalShow} onHide={closeModal}>
        <KpiTable kpiData={modalContent} years={yearsAvailable} />
      </ModalDetails>
      <ModalDetails
        titleModal={modalUploadTitle}
        type={'md'}
        show={modalUploadShow}
        onHide={closeModal}
        actionButton={
          <Button variant='success' id='' onClick={(e) => handleUploadClick()}>
            Upload{' '}
            <span>
              <IcoUploadFile className='light-color ico-small cancel' />
            </span>
          </Button>
        }>
        {showAlert && (
          <Alert variant='danger'>
            <Trans code='A0522' />
          </Alert>
        )}
        <KpiFormUpload formErrors={showAlert} onFileSelected={handleFileSelect} />
      </ModalDetails>
    </div>
  );
};
export default StepSubKpi;
