import React, { useEffect, useState } from 'react';
import { Row, Col, ButtonGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getESGCategories, getESGSubCategories, getOEESGRatings } from '@lib/api';
import { fetchData } from '@lib/helpers/index';
import { Trans } from '@lib/i18n/index';
const EsgRatingComponent = ({ data, isSummary = false }) => {
  const [variantEsg, setVariantEsg] = useState([]);
  const [scoreESG, setScoreEsg] = useState('');
  const [projectRatings, setProjectRatings] = useState({});
  const [numberOfDuplicate, setNumberOfDuplicate] = useState(0);
  const [indicators, setIndicators] = useState([]);
  const [ratings, setRatings] = useState([]);

  const renderTooltip = (props) => {
    let message = '';

    if (props.popper.state) {
      message = props.popper.state.options.testObj;
    }

    return (
      <Tooltip id='tooltip-disabled' {...props}>
        {message}
      </Tooltip>
    );
  };
  console.log('data', data);
  const RatingComponent = ({ rating }) => {
    return (
      <Button className={`button-group-base ${rating.outlook}`} size='lg' disabled>
        {rating.code}
      </Button>
    );
  };
  useEffect(() => {
    if (!data) return;
    setVariantEsg(data);
  }, [data]);

  useEffect(() => {
    fetchData(getOEESGRatings, setRatings, null, [], null, null, null);

    // fetchData(
    //   getESGSubCategories,
    //   null,
    //   (data) => {
    //     setEnvironmentalSubcategories(data.filter((cat) => cat.category === 'E').flat());
    //     setSocialSubcategories(data.filter((cat) => cat.category === 'S').flat());
    //     setGovernanceSubcategories(data.filter((cat) => cat.category === 'G').flat());
    //   },
    //   [],
    //   null,
    //   null,
    //   null
    // );
    setProjectRatings(() => ({
      esg: variantEsg
        .filter((rating) => rating.type === 'total')
        .map((filteredRating) => filteredRating.rating)
        .join(),
      E: variantEsg
        .filter((rating) => rating.type === 'category' && rating.category === 'E')
        .map((filteredRating) => filteredRating.rating)
        .join(),
      S: variantEsg
        .filter((rating) => rating.type === 'category' && rating.category === 'S')
        .map((filteredRating) => filteredRating.rating)
        .join(),
      G: variantEsg
        .filter((rating) => rating.type === 'category' && rating.category === 'G')
        .map((filteredRating) => filteredRating.rating)
        .join(),
    }));

    if (variantEsg.length > 0) {
      const totalScoreEsg =
        variantEsg && variantEsg.filter((el) => el.type === 'total').map((el) => el.scorePerc);

      setScoreEsg(totalScoreEsg.length > 0 ? totalScoreEsg.join().replace('.', ',') : 'N/A');
    }
  }, [variantEsg]);

  const indicatorESG = () => {
    let indicator = [];
    const score = variantEsg
      .filter((rating) => rating.type === 'total')
      .map((filteredRating) => filteredRating.scorePerc.toLocaleString('it-IT'));

    ratings
      .sort((ratingA, ratingB) => ratingA.min - ratingB.min)
      .forEach((rating) => {
        if (rating.code === projectRatings.esg) {
          indicator.push(
            <OverlayTrigger
              key={rating.code}
              placement='top'
              delay={{ show: 200, hide: 400 }}
              overlay={renderTooltip}
              popperConfig={{ testObj: score }}>
              <span className='d-inline-block no-to-head'>
                <Button
                  className='button-group-rating showRatingESG rounded'
                  size='lg'
                  disabled
                  style={{ pointerEvents: 'none' }}>
                  <div className='arrow-up'></div>ESG
                </Button>
              </span>
            </OverlayTrigger>
          );
        } else {
          indicator.push(
            <Button
              key={rating.code}
              variant='outline-dark'
              className='button-group-rating'
              size='lg'
              disabled>
              {/* invisible */}
            </Button>
          );
        }
      });
    return indicator;
  };
  useEffect(() => {
    const { esg, ...restProjectRatings } = projectRatings;
    let tempIndicators = [];

    const duplicateElements = Object.values(restProjectRatings).filter(
      (value, index) => Object.values(restProjectRatings).indexOf(value) !== index
    );

    if (duplicateElements.length === 0) {
      setNumberOfDuplicate(0);
    } else {
      setNumberOfDuplicate(
        Object.values(restProjectRatings).filter((val) => val === duplicateElements[0]).length
      );
    }

    if (Object.keys(projectRatings).length > 0) {
      ratings
        .sort((ratingA, ratingB) => ratingA.min - ratingB.min)
        .filter((rating) => {
          if (Object.values(restProjectRatings).some((code) => code === rating.code)) {
            const codes = Object.keys(restProjectRatings).filter(
              (rest) => restProjectRatings[rest] === rating.code
            );

            return tempIndicators.push(
              <Button
                key={rating.code}
                className='button-group-rating showRatingESG rounded'
                size='lg'
                disabled>
                {codes.map((code) => {
                  const score = variantEsg
                    .filter((rating) => rating.type === 'category' && rating.category === code)
                    .map((filteredRating) => filteredRating.scorePerc.toLocaleString('it-IT'));

                  return (
                    <OverlayTrigger
                      key={code}
                      placement='top'
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}
                      popperConfig={{ testObj: score }}>
                      <span id={rating.code}>
                        {code}
                        <div className='arrow-down'></div>
                      </span>
                    </OverlayTrigger>
                  );
                })}
              </Button>
            );
          } else {
            return tempIndicators.push(
              <Button
                key={rating.code}
                id={rating.code}
                variant='outline-dark'
                className='button-group-rating'
                size='lg'
                disabled>
                {/* invisible */}
              </Button>
            );
          }
        });

      setIndicators(tempIndicators);
    }
  }, [projectRatings, ratings, variantEsg]);

  return (
    <div className={`${!isSummary ? 'simple-box impact' : ''}`}>
      {!isSummary && (
        <div className='row'>
          <div className='col'>
            <h5 className='title-simple-box margin-title-box'>
              <Trans code='A0847' />
            </h5>
          </div>
        </div>
      )}

      {variantEsg && (
        <Row className='px-2 align-items-center justify-content-between mb-4 flex-wrap'>
          <Col className='pr-0 d-flex justify-content-center align-items-center'>
            <div className='d-flex justify-content-center align-items-center'>
              <table className='mt-2' style={{ fontSize: '1.5rem' }}>
                <thead>
                  <tr className='py-2'>
                    <td className='pr-4 pb-4'></td>
                    <td className='pr-4 pb-4 esg-header columns'>Puntuale</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='pr-4 pb-4 esg-header rows text-uppercase'>SCORING</td>
                    <td className='pr-4 pb-4 f-600'>{scoreESG}</td>
                  </tr>
                  <tr>
                    <td className='pr-4 pb-4 esg-header rows text-uppercase'>RATING</td>
                    <td className='pr-4 pb-4 f-600'>{projectRatings.esg ?? 'N/A'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>

          <Col
            className='esg-infobox text-center pl-0 mt-5'
            style={{
              flexGrow: 2,
              marginTop:
                numberOfDuplicate === 0
                  ? '0.5rem'
                  : numberOfDuplicate === 2
                  ? '-1.5rem'
                  : '-3.5rem',
            }}>
            <Row style={{ flexDirection: 'column' }}>
              <Col>
                <ButtonGroup
                  className='buttongroup-esg indicator align-items-end'
                  id='buttongroup-indicators'>
                  {Object.keys(projectRatings).length > 0 ? indicators : null}
                </ButtonGroup>
              </Col>

              <Col className='mt-2 mb-2'>
                <ButtonGroup className='buttongroup-esg'>
                  {ratings
                    .sort((ratingA, ratingB) => ratingA.min - ratingB.min)
                    .map((rating) => {
                      return <RatingComponent rating={rating} key={rating.code} />;
                    })}
                </ButtonGroup>
              </Col>
              <Col>
                <ButtonGroup className='buttongroup-esg indicator'>
                  {Object.keys(projectRatings).length > 0 ? indicatorESG() : null}
                </ButtonGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default EsgRatingComponent;
