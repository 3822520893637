import React, { useRef } from 'react';
import { Trans } from '@lib/i18n';
import { ListGroup } from 'react-bootstrap';
import ListItem from '@components/scenariodashboard/DashboardListItem';
import WaterfallChartGraph from '@components/scenariodashboard/WaterfallChart.js';
import { Ico42 } from '@icons/new';
import useResizeListener from '@lib/hooks/useResizeListener';
import { formatNumberWithLocale } from '@lib/helpers/index';

const DashboardSroiOutcomes = ({
  syntheticResults: { benefici, costi, bC, vane, tire, sroi, avgSroi },
  currencyToUse,
}) => {
  const waterfallRef = useRef(null);
  const width = useResizeListener(waterfallRef);
  const data = [
    { propertyName: 'Benefici Generati', propertyValue: benefici },
    { propertyName: 'Spesa Totale', propertyValue: costi },
    { propertyName: 'Valore Economico Netto', propertyValue: bC },
  ];
  console.log('COSA VEDIAMO', sroi, avgSroi);
  return (
    <div className='simple-box impact'>
      <Ico42 className='logo-card ico-box-blue' />
      <div className='row'>
        <div className='col'>
          <h5 className='title-simple-box margin-title-box'>
            <Trans code='A0737' />
          </h5>
        </div>
      </div>

      <div
        className='d-flex flex-wrap justify-content-around flex-grow-1'
        style={{ position: 'relative', top: '-50px' }}>
        <div className='chartBox impactChartTab d-flex col pt-0' style={{ alignItems: 'flex-end' }}>
          <ListGroup variant='flush' className='pt-0 flex-grow-1'>
            <ListItem listTitle={'A0754'} listValue={formatNumberWithLocale(benefici)} sign={''} />
            <ListItem listTitle={'A0755'} listValue={formatNumberWithLocale(costi)} sign={''} />
            <ListItem listTitle={'ENPV'} listValue={formatNumberWithLocale(vane)} sign={''} />
            <ListItem
              listTitle={'EIRR'}
              listValue={formatNumberWithLocale(tire * 100)}
              sign='&#37;'
            />
          </ListGroup>
        </div>
        <div
          className='chartBox impactChartTab d-flex align-items-flex-end col pt-0'
          style={{ alignItems: 'flex-end' }}>
          <ListGroup variant='flush' className='pt-0 flex-grow-1'>
            <ListItem listTitle={'SROI'} listValue={formatNumberWithLocale(sroi, true)} />
            <ListItem listTitle={'A0761'} listValue={formatNumberWithLocale(avgSroi, true)} />
          </ListGroup>
        </div>
        <div
          ref={waterfallRef}
          className='chartBox impactChartTab flex-grow-1 col waterfallBox pt-0'>
          <WaterfallChartGraph data={data} height={250} width={width} sign={''} />
        </div>
      </div>
    </div>
  );
};

export default DashboardSroiOutcomes;
