import CorporateEsgHeader from 'components/CorporateEsg/CorporateEsgHeader';
import CorporateEsgGeneral from 'components/CorporateEsg/CorporateEsgGeneral';
import React, { useEffect, useState } from 'react';
import { useParams, Redirect, useLocation, Route, Switch } from 'react-router-dom';
import CorporateEsgKpis from 'components/CorporateEsg/CorporateEsgKpis';
import CorporateEsgQuestionnaire from 'components/CorporateEsg/CorporateEsgQuestionnaire';
import CorporateEsgResults from 'components/CorporateEsg/CorporateEsgResults';
import CorporateEsgAnalyses from 'components/CorporateEsg/CorporateEsgAnalyses';
import { getCorporateAnalysesKpis } from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';

const CorporateEsgPage = () => {
  const { companyId, analysisId } = useParams();
  // const userCompanyId = JSON.parse(localStorage.getItem('user')).idOrganization || null;
  const userRole = JSON.parse(localStorage.getItem('user'))?.role || null;
  const [refresh, setRefresh] = useState(false);
  const [kpis, setKpis] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { section } = useParams();
  const location = useLocation();
  useEffect(() => {
    if (!companyId || !analysisId) return;
    fetchData(
      getCorporateAnalysesKpis,
      setKpis,
      null,
      [companyId, analysisId],
      null,
      setError,
      setIsLoading
    );
  }, [companyId, analysisId]);

  useEffect(() => {
    if (!companyId || !analysisId) return;
    if (refresh) {
      fetchData(
        getCorporateAnalysesKpis,
        setKpis,
        null,
        [companyId, analysisId],
        null,
        setError,
        setIsLoading
      );
    }
    setRefresh(false);
  }, [companyId, analysisId, refresh]);

  if (userRole > 3) {
    return (
      <div id='wizard' className='scenari megaContent'>
        <div className='container-fluid'>
          <div className='simple-box'>
            <h2 className='mb-0'>Unauthorized</h2>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div id='wizard' className='scenari megaContent'>
        <div className='container-fluid'>
          {location.pathname !== '/corporateesg' && (
            <CorporateEsgHeader stepName={section} kpis={kpis} />
          )}
          <Switch>
            <Route exact path='/corporateesg' component={CorporateEsgAnalyses} />
            <Route
              path='/corporateesg/company/:companyId/analysis/:analysisId/general'
              component={CorporateEsgGeneral}
            />
            <Route
              path='/corporateesg/company/:companyId/analysis/:analysisId/kpis'
              render={(props) => (
                <CorporateEsgKpis
                  setRefresh={setRefresh}
                  refresh={refresh}
                  kpis={kpis}
                  setKpis={setKpis}
                  error={error}
                  isLoading={isLoading}
                />
              )}
            />
            <Route
              path='/corporateesg/company/:companyId/analysis/:analysisId/questionnaire'
              component={CorporateEsgQuestionnaire}
            />
            <Route
              path='/corporateesg/company/:companyId/analysis/:analysisId/results'
              component={CorporateEsgResults}
            />
            <Route path='*'>
              <Redirect to='/corporateesg' />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default CorporateEsgPage;
