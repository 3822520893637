import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import QuestionText from './QuestionText';
import TooltipComponent from './TooltipComponent';

const InputPercentage = ({ question, handleAssessmentChange, handleValue }) => {
  const handleInputChange = (e, questionId, answerId, type) => {
    let value = parseInt(e.target.value, 10);

    if (isNaN(value)) {
      value = 0;
    }
    // Ensure value does not exceed 100
    if (value > 100) {
      value = 100;
    }

    // Call the parent handler with the validated value
    handleAssessmentChange(
      { ...e, target: { ...e.target, value: value.toString() } },
      questionId,
      answerId,
      type
    );
  };

  return (
    <QuestionText question={question}>
      <div key={`default-input-${question.id}`} className='mb-3'>
        {question.answers.map((answer) => (
          <div key={answer.id} className='d-flex align-items-center mb-3 row'>
            <div className='col d-flex align-items-center mb-3'>
              <Form.Group className='mb-0'>
                <InputGroup>
                  <Form.Control
                    type='number'
                    id={`${answer.id}`}
                    name={`${question.id}`}
                    min='0'
                    max='100'
                    onChange={(e) => handleInputChange(e, question.id, answer.id, question.type)}
                    style={{ maxWidth: '10rem', textAlign: 'right' }}
                    value={handleValue(question.id, answer.id)}
                    // defaultValue={0}
                  />
                  <InputGroup.Text className='addon-for-number'>&#37;</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              {answer.description ? <TooltipComponent data={answer.description} /> : null}
            </div>
          </div>
        ))}
      </div>
    </QuestionText>
  );
};

export default InputPercentage;
