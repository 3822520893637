import React from 'react';
import { Form, InputGroup, Row, Col } from 'react-bootstrap';
import { Trans } from '@lib/i18n/index';

const CorporateEsgKpiIndicatorComponent = ({ data, index, onChange }) => {
  return (
    <Row className='mx-5 my-4'>
      <Col col={5}>
        <h2 className='m-0'>{data.indicator}</h2>
      </Col>
      <Col col={7} className='d-flex justify-content-end align-items-center'>
        <div className='d-flex'>
          <div className='mr-3'>
            <Form.Group className='d-flex align-items-center' controlId='value'>
              <Form.Label className='mr-3'>
                <Trans code='A0862' />
              </Form.Label>
              <InputGroup className='mb-2' style={{ minWidth: '200px', maxWidth: '300px' }}>
                <InputGroup.Prepend>
                  <InputGroup.Text style={{ minWidth: '40px' }}>{data.udm}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type='number'
                  name='value'
                  // placeholder='es. 33000'
                  maxLength='10'
                  min={0}
                  // max={10}
                  step={0.1}
                  value={data.value}
                  onChange={(e) => onChange(e, 'value', index)}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CorporateEsgKpiIndicatorComponent;
