import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCorporateAnalysesResults, getESGSubCategories } from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
import CustomSpinner from 'components/CustomSpinner';
import EsgMainComponent from 'components/scenariodashboard/esgRating/esgMainComponent';
import StatusBar from 'components/wizard/StatusBar';
import { Trans } from '@lib/i18n/index';
const CorporateEsgResults = () => {
  const { companyId, analysisId } = useParams();
  // const userCompanyId = JSON.parse(localStorage.getItem('user')).idOrganization || null;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [esgResults, setEsgResults] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    if (!companyId || !analysisId) return;
    fetchData(
      getCorporateAnalysesResults,
      setEsgResults,
      null,
      [companyId, analysisId],
      null,
      setError,
      setIsLoading
    );
    fetchData(getESGSubCategories, setSubcategories, null, [], null, setError, setIsLoading);
  }, [companyId, analysisId]);

  return (
    <div className='summaryDashboard'>
      <h1 className='pb-0 mt-5' style={{ marginBottom: '40px' }}>
        <Trans code='A0851' />
      </h1>
      <div>
        {!esgResults.length ? (
          <h1>Nessun Dato da visualizzare</h1>
        ) : (
          !!esgResults.length &&
          !!subcategories.length && (
            <EsgMainComponent esgResults={esgResults} subcategories={subcategories} />
          )
        )}

        {error && <StatusBar status={'error'} message={error} timeout={false} />}
      </div>
    </div>
  );
};

export default CorporateEsgResults;
