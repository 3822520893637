import { getESGCategories, getESGSubCategories } from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
import StatusBar from 'components/wizard/StatusBar';
import React, { useEffect, useState } from 'react';

import { Button, Form } from 'react-bootstrap';
import { Trans } from '@lib/i18n/index';
import CounterComponent from './CounterComponent';
import Category from './CategoriesItems';

const EsgComponent = ({
  questionsData,
  answersData,
  setAnswersData,
  postFunction,
  isLoading,
  sbShow,
  formId = 'form-subesg',
  shouldStick = true,
  shouldAnswerAll = true,
}) => {
  const [esgCategories, setEsgCategories] = useState([]);
  const [esgSubCategories, setEsgSubCategories] = useState([]);
  const [dependsOnAnswers, setDependsOnAnswers] = useState([]);
  const [error, setError] = useState(null);

  const [questionVisibility, setQuestionVisibility] = useState(() => {
    // This function determines the initial visibility of questions based on their dependencies
    if (!questionsData) return {};
    const initialVisibility = {};
    questionsData.forEach((question) => {
      if (question.dependsOnAnswers !== 0) {
        const dependsOnAnswerId = parseInt(question.dependsOnAnswers);
        const answer = answersData.find((a) =>
          a.answers.some((ans) => ans.answerId === dependsOnAnswerId)
        );
        initialVisibility[question.questionId] = answer?.value === 'on';
      } else if (question.dependsOnAnswer !== 0) {
        const dependsOnAnswerId = parseInt(question.dependsOnAnswer);
        const answer = answersData.find((a) =>
          a.answers.some((ans) => ans.answerId === dependsOnAnswerId)
        );
        initialVisibility[question.questionId] = answer?.value === 'on';
      } else {
        initialVisibility[question.questionId] = true; // Show by default if no dependsOnAnswers or dependsOnAnswer
      }
    });
    return initialVisibility;
  });

  const idDependsOnAnswerFullfilled = (questions) => {
    const commaSeparatedPattern = /^\d+(,\s*\d+)*$/;
    const questionsAss = [...questions];
    const answers = questionsAss
      .filter((el) => el.dependsOnAnswers)
      .map((question) => ({
        questionId: question.id,
        dependsOnAnswers: commaSeparatedPattern.test(question.dependsOnAnswers)
          ? question.dependsOnAnswers.split(',').map((el) => parseInt(el))
          : parseInt(question.dependsOnAnswers),
      }));
    setDependsOnAnswers(answers);
  };

  useEffect(() => {
    fetchData(getESGCategories, setEsgCategories, null, [], null, setError);
    fetchData(getESGSubCategories, setEsgSubCategories, null, [], null, setError);
  }, []);

  useEffect(() => {
    if (!questionsData) return;
    idDependsOnAnswerFullfilled(questionsData);
  }, [questionsData]);

  const filterDependsAnswers = (dependsOnAnswersArray, answersArray) => {
    let answersArrayCopy = [...answersArray];
    const secondArrayMap = new Map();
    answersArrayCopy.forEach((obj, index) => {
      secondArrayMap.set(obj.questionId, { ...obj, index });
    });
    const secondArrayAnsId = answersArrayCopy
      .map((obj) => obj.answers.map((el) => el.answerId))
      .flatMap((el) => el);

    dependsOnAnswersArray.forEach((firstObj) => {
      const secondObj = secondArrayMap.get(firstObj.questionId);

      if (secondObj) {
        const firstArrayDepends = firstObj.dependsOnAnswers;

        // Check if all answerId values in dependsOnAnswers are present in secondArrayAnswers
        const allAnswersPresent = firstArrayDepends.every((element) =>
          secondArrayAnsId.includes(element)
        );
        // If not all required answerIds are present, remove the object from the second array
        if (!allAnswersPresent) {
          answersArrayCopy.splice(secondObj.index, 1);
          // Adjust indices in secondArrayMap after removal
          for (let i = secondObj.index; i < answersArrayCopy.length; i++) {
            secondArrayMap.set(answersArrayCopy[i].questionId, {
              ...answersArrayCopy[i],
              index: i,
            });
          }
        }
      }
    });

    return answersArrayCopy;
  };

  const handleAssessmentChange = (e, questionId, answerId, type) => {
    const { value, checked } = e.target;

    setAnswersData((prevAnswers) => {
      let updatedAnswers = [...prevAnswers];

      const questionIndex = updatedAnswers.findIndex((q) => q.questionId === questionId);

      if (questionIndex === -1) {
        updatedAnswers.push({
          questionId,
          answers: [
            {
              answerId,
              value:
                type === 'checkbox' ? (checked ? 'on' : null) : type === 'radio' ? 'on' : value,
              // note: null,
            },
          ],
        });
      } else {
        const answerIndex = updatedAnswers[questionIndex].answers.findIndex(
          (a) => a.answerId === answerId
        );
        if (type === 'checkbox') {
          if (answerIndex === -1) {
            updatedAnswers[questionIndex].answers.push({ answerId, value: checked ? 'on' : null });
          } else {
            updatedAnswers[questionIndex].answers[answerIndex].value = checked ? 'on' : null;
          }
        } else if (type === 'radio') {
          if (answerIndex === -1) {
            updatedAnswers[questionIndex].answers.push({ answerId, value: checked ? 'on' : null });
          }
          updatedAnswers[questionIndex].answers.forEach((a) => {
            a.value = a.answerId === answerId ? 'on' : null;
          });
        } else {
          if (answerIndex === -1) {
            updatedAnswers[questionIndex].answers.push({
              answerId,
              value: type === 'input-num' ? parseFloat(value) : value,
            });
          } else {
            updatedAnswers[questionIndex].answers[answerIndex].value = value;
          }
        }

        updatedAnswers[questionIndex].answers = updatedAnswers[questionIndex].answers.filter(
          (a) => a.value !== null && a.value !== ''
        );
        if (updatedAnswers[questionIndex].answers.length === 0) {
          updatedAnswers.splice(questionIndex, 1);
        }
      }

      return filterDependsAnswers(dependsOnAnswers, updatedAnswers);
    });
  };

  useEffect(() => {
    if (!questionsData) return;
    const singleNumberPattern = /^\d+$/;
    const commaSeparatedPattern = /^\d+(,\s*\d+)*$/;
    const updatedVisibility = {};
    questionsData.forEach((question) => {
      if (
        question.dependsOnAnswers !== null &&
        singleNumberPattern.test(question.dependsOnAnswers)
      ) {
        const dependsOnAnswerId = parseInt(question.dependsOnAnswers);
        const answer = answersData.find((a) =>
          a.answers.some((ans) => ans.answerId === dependsOnAnswerId)
        )?.answers;
        updatedVisibility[question.id] =
          answer?.find((el) => el.answerId === dependsOnAnswerId)?.value === 'on';
      } else if (
        question.dependsOnAnswers !== null &&
        commaSeparatedPattern.test(question.dependsOnAnswers)
      ) {
        const dependsOnAnswerIds = question.dependsOnAnswers.split(',').map((el) => parseInt(el));
        updatedVisibility[question.id] = dependsOnAnswerIds.every((id) => {
          const answer = answersData.find((a) =>
            a.answers.some((ans) => ans.answerId === id)
          )?.answers;
          return answer?.find((el) => el.answerId === id)?.value === 'on';
        });
      } else {
        updatedVisibility[question.id] = true;
      }
    });
    setQuestionVisibility(updatedVisibility);
  }, [answersData, questionsData]);

  const handleChecked = (questionID, answerID) => {
    let boolean = false;
    answersData.forEach((el) => {
      if (Number(el.questionId) === questionID) {
        const checkedAnswers = el.answers.find((answer) => answer.answerId === answerID);
        if (checkedAnswers && checkedAnswers?.value === 'on') {
          boolean = true;
        } else {
          boolean = false;
        }
      }
    });
    return boolean;
  };

  const handleValue = (questionID, answerID) => {
    const question = answersData.find((el) => el.questionId === questionID);
    return question?.answers.find((ans) => ans.answerId === answerID)?.value || '';
  };
  // const handleEditQuestionnaire = async (e) => {
  //   e.preventDefault();
  //   console.log('answers', answers);
  //   // setIsLoading(true);
  //   setError(null);
  //   const answersToSend = { questions: [...answers] };
  //   console.log('answersToSend', answersToSend);
  //   const response = await postESGVariantQuestionnaireAnswers(
  //     idScenario,
  //     idVariante,
  //     answersToSend
  //   );
  //   if (response.status) {
  //     setIsLoading(false);
  //     handleStatusBar('success', 'Questionnaire saved successfully', true);
  //     // setVariantRefresh(true);
  //   } else {
  //     setError(response.message);
  //   }
  // };

  return (
    <div className='simple-box mt-2'>
      <div className='d-flex justify-content-between'>
        <h5 className='title-simple-box margin-title-box'>ESG</h5>
        <CounterComponent
          answeredNumber={answersData.length}
          toAnswerNumber={
            questionVisibility &&
            Object.keys(questionVisibility)
              .map((key) => questionVisibility[key])
              .filter((el) => el === true).length
          }
        />
      </div>

      <div>
        <Form onSubmit={postFunction} id={formId}>
          {esgCategories
            .sort((a, b) => a.order - b.order)
            .map((category) => (
              <Category
                key={category.code}
                category={category}
                subCategories={esgSubCategories}
                questions={questionsData}
                handleAssessmentChange={handleAssessmentChange}
                questionVisibility={questionVisibility}
                handleValue={handleValue}
                handleChecked={handleChecked}
              />
            ))}
          <Button
            form={formId}
            id='save-general-button'
            type='submit'
            className={`submit-button ${shouldStick ? 'sticky-footer-button' : ''}`}
            disabled={
              shouldAnswerAll
                ? isLoading ||
                  Object.keys(questionVisibility)
                    .map((key) => questionVisibility[key])
                    .filter((el) => el === true).length !== answersData.length
                : isLoading
            }>
            <Trans code='F00046' />
            {/* {!isLoading ? (
            <Trans code='F00046' />
          ) : (
            <span className='d-flex align-items-center ml-2'>
              <Trans code='A0782' />
              <Spinner animation='border' className='ml-2' size='sm' />
            </span>
          )} */}
          </Button>
        </Form>
      </div>
      {sbShow}
      {error && <StatusBar status={'error'} message={error} timeout={false} />}
    </div>
  );
};

export default EsgComponent;
