import React, { useEffect, useState } from 'react';
import { Col, Form, Row, OverlayTrigger, Tooltip, Button, Spinner } from 'react-bootstrap';
import { Trans } from '@lib/i18n/index';
import Select from 'react-select';
import { IcoMinusCircled, IcoPlus } from 'icone/index';
import { postCompaniesLocations, putCompaniesLocations } from '@lib/api/index';
import StatusBar from 'components/wizard/StatusBar';

const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
};

const CorporateEsgGeneralForm = ({
  onChange,
  index,
  regionOptions,
  provinceOptions,
  corporation,
  handleAddCorporation,
  handleRemoveCorporation,
  isLastItem = false,
  isLoading,
  setIsLoading,
  companyId,
  hasHeadquarter,
  setCorporationData,
  originalState,
  setOriginalState,
}) => {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [error, setError] = useState(null);
  const [isEqualResult, setIsEqualResult] = useState(true);
  const renderTooltip = (props) => {
    let message = '';
    if (props.popper.state) {
      message = props.popper.state.options.testObj;
    }

    return (
      <Tooltip id='button-tooltip' {...props}>
        {message}
      </Tooltip>
    );
  };

  useEffect(() => {
    if (index !== null && corporation !== null) {
      const result = deepEqual(originalState[index], corporation);
      setIsEqualResult(result);
    }
  }, [originalState, corporation, index]);

  useEffect(() => {
    if (corporation.region) {
      setSelectedRegion(regionOptions.find((el) => el.value === corporation.region));
    }
  }, [corporation, regionOptions]);

  useEffect(() => {
    if (selectedRegion && corporation.province) {
      setSelectedProvince(provinceOptions.find((el) => el.value === corporation.province));
    }
  }, [corporation, provinceOptions, selectedRegion]);

  const submit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    let createResult;
    if (corporation?.id) {
      createResult = await putCompaniesLocations(companyId, corporation.id, corporation);
    } else {
      createResult = await postCompaniesLocations(companyId, corporation);
    }

    if (createResult.status) {
      const updatedCorporation = createResult.data;
      setCorporationData((prevCorporationState) => {
        const updatedCorporationState = [...prevCorporationState];
        updatedCorporationState[index] = updatedCorporation;
        return updatedCorporationState;
      });
      setOriginalState((prevCorporationState) => {
        const updatedCorporationState = [...prevCorporationState];
        updatedCorporationState[index] = updatedCorporation;
        return updatedCorporationState;
      });
      // handleStatusBar('success', 'Valore Salvato correttamente', true);
    } else {
      createResult?.message
        ? setError(createResult.message)
        : setError(`Errore nel salvataggio dell'indirizzo`);
    }
    setIsLoading(false);
  };

  return (
    <div className='simple-box' style={{ marginTop: '40px' }}>
      <Row>
        <Col lg={11} className='form-login'>
          <h5 className='title-simple-box margin-title-box'>
            <Trans code='A0745' /> {index + 1}
            {/* <Trans code='A0493' /> */}
          </h5>
          <Form onSubmit={submit} id={`form-corporateesg-general_${index}`}>
            <Row className='mb-3'>
              <Form.Group as={Col} controlId='region'>
                <Form.Label>
                  <Trans code='A0668' /> &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Select
                  name='region'
                  value={selectedRegion}
                  options={regionOptions}
                  onChange={(e) =>
                    onChange(e, index, 'region', setSelectedRegion, setSelectedProvince)
                  }
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  isDisabled={isLoading}
                />
              </Form.Group>
              <Form.Group as={Col} controlId='province'>
                <Form.Label>
                  <Trans code='A0854' /> &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Select
                  name='province'
                  value={selectedProvince}
                  options={provinceOptions.filter((el) => el.value.includes(selectedRegion?.value))}
                  onChange={(e) => onChange(e, index, 'province', setSelectedProvince)}
                  isDisabled={!selectedRegion || isLoading}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId='city'>
                <Form.Label>
                  <Trans code='A0757' /> &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='city'
                  value={corporation.city || ''}
                  onChange={(e) => onChange(e, index, 'city')}
                  disabled={isLoading}
                />
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} controlId='address'>
                <Form.Label>
                  <Trans code='A0745' /> &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='city'
                  value={corporation.address || ''}
                  onChange={(e) => onChange(e, index, 'address')}
                  disabled={isLoading}
                />
              </Form.Group>
              <Form.Group as={Col} controlId='postalCode'>
                <Form.Label>
                  <Trans code='A0855' /> &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='postalCode'
                  value={corporation.postalCode || ''}
                  onChange={(e) => onChange(e, index, 'postalCode')}
                  disabled={isLoading}
                />
              </Form.Group>
              <Form.Group as={Col} controlId='surface'>
                <Form.Label>
                  <Trans code='A0856' /> &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='number'
                  name='surface'
                  value={corporation.surface || ''}
                  onChange={(e) => onChange(e, index, 'surface')}
                  disabled={isLoading}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='headquarter'>
                <Form.Label className='mb-3'>
                  <Trans code='A0857' />
                </Form.Label>
                <Form.Control
                  style={{ width: '15px' }}
                  type='checkbox'
                  name='headquarter'
                  checked={corporation.headquarter}
                  onChange={(e) => onChange(e, index, 'headquarter')}
                  disabled={(hasHeadquarter && !corporation.headquarter) || isLoading}
                />
              </Form.Group>
            </Row>
            <div>
              {!isEqualResult && (
                <div className='d-flex justify-content-center align-items-center'>
                  <p className='mb-1 not-saved-text'>
                    <Trans code='A0853' />
                  </p>
                </div>
              )}
              <Button
                id='save-button'
                form={`form-corporateesg-general_${index}`}
                type='submit'
                className='submit-button mt-0'
                disabled={isLoading}>
                {!isLoading ? (
                  <Trans code='F00046' />
                ) : (
                  <span className='d-flex align-items-center ml-2'>
                    <Trans code='A0782' />
                    <Spinner animation='border' className='ml-2' size='sm' />
                  </span>
                )}
              </Button>
            </div>
          </Form>
        </Col>
        <Col lg={1} className='d-flex align-items-center justify-content-center'>
          {isLastItem && (
            <OverlayTrigger
              placement='left'
              delay={{ show: 200, hide: 400 }}
              overlay={renderTooltip}
              popperConfig={{ testObj: 'Aggiungi un nuovo indirizzo' }}>
              <span className='d-inline-block no-to-head'>
                <Button
                  disabled={isLoading}
                  variant='link'
                  // className='mr-3'
                  onClick={handleAddCorporation}>
                  <IcoPlus className='second-color ico-small indicators' />
                </Button>
              </span>
            </OverlayTrigger>
          )}
          <OverlayTrigger
            placement='left'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{ testObj: 'Rimuovi indirizzo' }}>
            <span className='d-inline-block no-to-head'>
              <Button
                disabled={isLoading}
                variant='link'
                onClick={(e) => handleRemoveCorporation(e, corporation, index)}>
                <IcoMinusCircled className='red-color ico-small indicators' />
              </Button>
            </span>
          </OverlayTrigger>
        </Col>
      </Row>
      {error && <StatusBar status={'error'} message={error} timeout={false} />}
    </div>
  );
};

export default CorporateEsgGeneralForm;
