import { date } from '@lib/helpers/index';
import { IcoCanc, IcoEdit } from 'icone/index';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CorporateEsgAnalysesModalDelete from './CorporateEsgAnalysesModalDelete';
import { Trans } from '@lib/i18n/index';
const CorporateEsgAnalysesCard = ({ data, userCompanyId, setRefresh }) => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const history = useHistory();
  const navigateToGeneral = () => {
    history.push(`/corporateesg/company/${data.companyId}/analysis/${data.id}/general`);
  };
  return (
    <div className='esg-analysis-card'>
      <div className='d-flex flex-column'>
        <h3>
          <Trans code='A0866' /> Nr. {data.id}
        </h3>
        <p className='mb-0 pb-0'>
          <Trans code='A0687' />: {data.status}
        </p>
        <p className='mb-0 pb-0'>
          <Trans code='A0867' />: {date(data.createdAt)}
        </p>
        <p className='mb-0 pb-0'>
          <Trans code='A0868' />: {data.executedAt ? date(data.executedAt) : '-'}
        </p>
      </div>
      <div className='button-container d-flex' style={{ gap: '10px' }}>
        <Button
          variant='link'
          onClick={(e) => {
            e.stopPropagation();
            navigateToGeneral();
          }}>
          <IcoEdit className='second-color ico-small edit' />
        </Button>

        {userCompanyId === data.companyId && (
          <Button
            variant='link'
            className='mr-0'
            onClick={(e) => {
              e.stopPropagation();
              // setDeleteId(data.id);
              setShowModalDelete(true);
            }}>
            <IcoCanc className='second-color ico-small cancel' />
          </Button>
        )}
      </div>
      <CorporateEsgAnalysesModalDelete
        showModalDelete={showModalDelete}
        setShowModalDelete={setShowModalDelete}
        companyId={data.companyId}
        analysisId={data.id}
        setRefresh={setRefresh}
      />
    </div>
  );
};

export default CorporateEsgAnalysesCard;
