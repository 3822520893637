import FlourishComponent from 'components/FlourishComponent/FlourishComponent';
import React, { useEffect, useState } from 'react';
import { propTrans } from '@lib/i18n';

const EsgChartsComponent = ({ data, category, subCategories, langCode }) => {
  const [esgData, setEsgData] = useState([]);
  const [finalData, setFinalData] = useState([]);

  useEffect(() => {
    if (!data || !category) return;
    setEsgData(data.filter((el) => el.category === category));
  }, [data, category]);

  const transformData = (data) => {
    const categoryItem = data.find((item) => item.type === 'category');
    const otherItems = data.filter((item) => item.type !== 'category');

    const sortedData = categoryItem ? [categoryItem, ...otherItems] : otherItems;
    const headersToConvert = sortedData
      .filter((item) => item.subcategory)
      .map((item) => item.subcategory);

    const headers = [
      'Name',
      ...headersToConvert.map((el) => subCategories.find((sub) => sub.code === el).text),
    ];

    const values = [
      propTrans({ prop: sortedData[0]?.langCode || langCode }) || '',
      ...sortedData.filter((item) => item.subcategory).map((item) => `${item.scorePerc}`),
    ];
    return [headers, values];
  };

  useEffect(() => {
    if (esgData.length > 0 && subCategories.length > 0) {
      setFinalData(transformData(esgData));
    }
  }, [esgData, subCategories]);

  const createArrayBasedOnLength = (length) => {
    return Array.from({ length }, (_, i) => i + 1);
  };

  const createIdenticalObjects = (length) => {
    const result = {
      0: {
        type_id: 'string$arbitrary_string',
        type: 'string',
        output_format_id: 'string$arbitrary_string',
      },
    };

    for (let i = 1; i <= length; i++) {
      result[i] = {
        type_id: 'number$comma_point',
        type: 'number',
        output_format_id: 'number$comma_point',
      };
    }

    return result;
  };
  console.log('finaldata', finalData);
  return (
    <>
      {finalData.length > 0 && (
        <FlourishComponent
          visualizationId='18333180'
          chartId={`radarEsgChartContainer_${category}`}
          style={
            {
              // width: '100%',
              // minWidth: '300px',
              // height: 'clamp(400px, 40vh, 500px)',
              // backgroundColor: 'grey',
            }
          }
          data={{
            bindings: {
              data: {
                facet: [],
                metadata: [],
                name: 0,
                values: createArrayBasedOnLength(esgData.length - 1),
              },
            },
            data: {
              data: finalData,
            },
            metadata: {
              data: createIdenticalObjects(esgData.length),
            },
          }}
          dataToRespond={[...finalData]}
        />
      )}
    </>
  );
};

export default EsgChartsComponent;
