import React, { useState, useRef, useEffect } from 'react';
import './treeview.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faCheckDouble,
  faSquare,
} from '@fortawesome/free-solid-svg-icons';
import { Button, InputGroup, Form } from 'react-bootstrap';

function TreeItem({
  item,
  data,
  isOpened,
  setIsOpened,
  parentId,
  checkedItems,
  setCheckedItems,
  disabled,
  extension,
}) {
  const [isOpen, setIsOpen] = useState(isOpened);
  const [isParentChecked, setIsParentChecked] = useState(false);
  const checkboxRef = useRef(null);

  useEffect(() => {
    setIsOpen(isOpened);
  }, [isOpened]);

  const updateChildChecks = (item, isChecked, checkedState) => {
    if (isChecked === true) {
      checkedState[item.code] = isChecked;
    } else {
      delete checkedState[item.code];
    }
    if (Object.keys(checkedState).some((el) => el.startsWith(item.code))) {
      let childKeys = Object.keys(checkedState);
      childKeys.forEach((el) => {
        if (el.startsWith(`${item.code}-`)) {
          delete checkedState[el];
        }
      });
    }
    if (item.code === 'WD') {
      let childKeys = Object.keys(checkedState);
      childKeys.forEach((el) => {
        if (el !== item.code) {
          delete checkedState[el];
        }
      });
    }
    // if (item.children) {
    //   item.children.forEach((child) => {
    //     updateChildChecks(child, isChecked, checkedState);
    //   });
    // }
  };

  // const areSiblingsAllChecked = (parentId, checkedState) => {
  //   const parentItem = data.find((item) => item.code.includes(parentId));
  //   if (parentItem && parentItem.children) {
  //     return parentItem.children.every((child) => checkedState[child.code]);
  //   }
  //   return false;
  // };

  useEffect(() => {
    if (item.children && item.children.length > 0) {
      const areAllChildrenChecked = item.children.every((child) => !!checkedItems[child.code]);

      if (areAllChildrenChecked !== isParentChecked) {
        setIsParentChecked(areAllChildrenChecked);
      }
    }
  }, [checkedItems, item.children]);

  const handleCheckboxChange = (item, isChecked) => {
    const updatedCheckedItems = { ...checkedItems };
    updateChildChecks(item, isChecked, updatedCheckedItems);

    setCheckedItems(updatedCheckedItems);
  };

  const checkDisbled = (item, checkedItems) => {
    return disabled
      ? disabled
      : extension === 'SR' && Object.keys(checkedItems).length === 1
      ? !checkedItems[item.code]
      : item?.country?.length > 0 && item?.region === ''
      ? !!checkedItems[item?.continent] || !!checkedItems['WD']
      : item?.country?.length > 0 && item?.region?.length > 0
      ? !!checkedItems[`${item?.continent}-${item?.country}`] ||
        !!checkedItems[item?.continent] ||
        !!checkedItems['WD']
      : (item?.country?.length > 0 ||
          item?.country === '' ||
          item?.region?.length > 0 ||
          item?.region === '') &&
        item?.continent !== 'WD'
      ? !!checkedItems['WD']
      : false;
  };

  return (
    <div className='tree-item'>
      <div className='node'>
        {item.children && (
          <span
            className={`toggle ${isOpen ? 'open' : 'closed'}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (e.target.nextSibling.innerText === 'World') {
                setIsOpened(!isOpened);
              } else {
                setIsOpen(!isOpen);
              }
            }}></span>
        )}
        <label>
          <input
            ref={checkboxRef}
            type='checkbox'
            checked={!!checkedItems[item.code]}
            onChange={(e) => {
              handleCheckboxChange(item, e.target.checked);
            }}
            disabled={checkDisbled(item, checkedItems)}
          />
          <span className={`ml-2 ${checkDisbled(item, checkedItems) && 'disabledCountry'}`}>
            {item.name}
          </span>
        </label>
      </div>
      {isOpen &&
        item.children &&
        item.children.map((child) => (
          <TreeItem
            key={child.code}
            item={child}
            data={data}
            parentId={item.code}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            isOpened={isOpen}
            disabled={disabled}
            extension={extension}
          />
        ))}
    </div>
  );
}

function TreeView({ data, regionData, onCheckedItemsChange, alreadyChecked, disabled, extension }) {
  const [filter, setFilter] = useState('');
  const [checkedItems, setCheckedItems] = useState({});

  const [expandAll, setExpandAll] = useState(true);
  const [showOnlyCheckedOrIntermediate, setShowOnlyCheckedOrIntermediate] = useState(false);

  const lastCheckedItemsRef = useRef();
  // console.log('REGIONDATA', regionData);
  const handleExpandCollapseAll = () => {
    setExpandAll((prevState) => !prevState);
  };

  const handleToggleShowChecked = () => {
    setShowOnlyCheckedOrIntermediate((prevState) => !prevState);
  };

  // const isItemCheckedOrIndeterminate = (item) => {
  //   if (checkedItems[item.code] || indeterminateItems[item.code]) return true;
  //   if (item.children) {
  //     return item.children.some(isItemCheckedOrIndeterminate);
  //   }
  //   return false;
  // };

  useEffect(() => {
    if (alreadyChecked.length > 0) {
      let checked = {};

      alreadyChecked.forEach((el) => {
        checked[el] = true;
      });
      setCheckedItems(checked);
    } else setCheckedItems({});
  }, [alreadyChecked]);

  useEffect(() => {
    if (onCheckedItemsChange) {
      if (lastCheckedItemsRef.current !== checkedItems) {
        onCheckedItemsChange(checkedItems);
        lastCheckedItemsRef.current = checkedItems;
      }
    }
  }, [checkedItems]);

  // const isItemCheckedOrIntermediate = (item) => {
  //   if (checkedItems[item.code]) return true;
  //   if (item.children) {
  //     return item.children.some(isItemCheckedOrIntermediate);
  //   }
  //   return false;
  // };

  // const filteredData = data.filter((item) => {
  //   if (showOnlyCheckedOrIntermediate) {
  //     return isItemCheckedOrIntermediate(item);
  //   }
  //   return (
  //     item.name.toLowerCase().includes(filter.toLowerCase()) ||
  //     item.children.some((child) => child.name.toLowerCase().includes(filter.toLowerCase()))
  //   );
  // });

  const filterData = (arr, filter, showOnlyCheckedOrIntermediate) => {
    // Se il filtro è vuoto e non si devono mostrare solo gli elementi selezionati o intermedi, restituisci l'array originale.
    if (filter === '' && !showOnlyCheckedOrIntermediate) {
      return arr;
    }

    // Utilizzo il metodo reduce per iterare sugli elementi dell'array.
    return arr.reduce((acc, item) => {
      // Qui verifico se il nome dell'elemento corrente corrisponde al filtro e se l'elemento è selezionato.
      let nameMatches;
      if (showOnlyCheckedOrIntermediate) {
        nameMatches = checkedItems[item.code] && item.name.toLowerCase().includes(filter);
      } else {
        nameMatches = item.name.toLowerCase().includes(filter);
      }
      const newItem = { ...item };
      // Se l'elemento ha figli, applico ricorsivamente la funzione di filtro ai figli.
      if (item.children) {
        newItem.children = filterData(item.children, filter, showOnlyCheckedOrIntermediate);
      }

      // Aggiungo l'elemento corrente all'array risultante se il nome corrisponde al filtro o se l'elemento ha figli che soddisfano le condizioni di filtro.

      // if (nameMatches || (newItem.children && newItem.children.length > 0)) {
      //   acc.push(newItem);
      // }

      if (nameMatches || (newItem.children && newItem.children.length > 0)) {
        if (newItem.children && newItem.children.length > 0) {
          acc.push(newItem);
        } else {
          const { children, ...rest } = newItem;
          acc.push(rest);
        }
      }
      // if (nameMatches || (newItem.children && newItem.children.length > 0)) {
      //   if (newItem.children && newItem.children.length > 0) {
      //     acc.push(newItem);
      //   } else {
      //     acc.push({ ...newItem, children: item.children });
      //   }
      // }

      // Restituisco l'array.
      return acc;
    }, []);
  };

  const filteredData = filterData(regionData, filter, showOnlyCheckedOrIntermediate);

  return (
    <div>
      <InputGroup size='sm' className='mb-3'>
        <Form.Control
          type='text'
          placeholder='Search...'
          aria-label='Search...'
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <Button size='sm' onClick={handleExpandCollapseAll} variant='outline-secondary'>
          {expandAll ? (
            <FontAwesomeIcon icon={faCompressArrowsAlt} />
          ) : (
            <FontAwesomeIcon icon={faExpandArrowsAlt} />
          )}
        </Button>
        <Button size='sm' onClick={handleToggleShowChecked} variant='outline-secondary'>
          {showOnlyCheckedOrIntermediate ? (
            <FontAwesomeIcon icon={faCheckDouble} />
          ) : (
            <FontAwesomeIcon icon={faSquare} />
          )}
        </Button>
      </InputGroup>
      {filteredData.map((item) => (
        <TreeItem
          key={item.code}
          item={item}
          data={regionData}
          parentId={item.code}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          isOpened={expandAll}
          setIsOpened={setExpandAll}
          disabled={disabled}
          extension={extension}
        />
      ))}
    </div>
  );
}

export default TreeView;
