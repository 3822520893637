import React, { useState, useEffect } from 'react';
import { useParams, Redirect, useLocation, Route, Switch } from 'react-router-dom';
import { propTrans } from '@lib/i18n';
// import i18n from '@lib/i18n';
// import Currency from '@lib/currency';
import { getOEScenario, getDatasetRegions, getOECurrencies } from '@lib/api';
import ScenarioHeaderCompact from '@components/scenariodashboard/ScenarioHeaderCompact';
import ScenarioImpactAnalysis from './impactAnalyses/scenarioImpactAnalysis';
import SummaryPage from './summaryPage/summaryPage';
import SroiAnalysesPage from './sroiAnalyses/sroiAnalysesPage';
import { getScenarioVariant } from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
import StatusBar from 'components/wizard/StatusBar';
import EsgRatingPage from './esgRating/esgRatingPage';

const ScenarioDashboard = () => {
  const { idScenario = null, idVariante = null } = useParams();
  const location = useLocation();
  const [scenario, setScenario] = useState(null);
  const [variant, setVariant] = useState(null);
  const [regions, setRegions] = useState([]);
  const [currencyToUse, setCurrencyToUse] = useState([]);
  const [sbShow, setSbShow] = useState(<></>);
  const [isLoading, setIsLoading] = useState(false);
  // const pathname = location.pathname.split('/').pop();

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  useEffect(() => {
    if (idScenario) {
      fetchData(
        getOEScenario,
        setScenario,
        null,
        [idScenario],
        handleStatusBar,
        null,
        setIsLoading
      );
    }
  }, [idScenario]);

  useEffect(() => {
    if (idVariante && idScenario) {
      fetchData(
        getScenarioVariant,
        setVariant,
        null,
        [idScenario, idVariante],
        handleStatusBar,
        null,
        setIsLoading
      );
    }
  }, [idScenario, idVariante]);

  useEffect(() => {
    if (variant && variant.analyses.some((el) => el.type === 'IA')) {
      fetchData(
        getDatasetRegions,
        setRegions,
        null,
        [variant.datasetId],
        handleStatusBar,
        null,
        setIsLoading
      );
    }
  }, [variant]);
  useEffect(() => {
    if (scenario) {
      fetchData(
        getOECurrencies,
        null,
        (data) => {
          const curr = data.filter((el) => el.code === scenario.currencyCode);
          setCurrencyToUse(curr);
        },
        [],
        handleStatusBar
      );
    }
  }, [scenario]);

  if (!idScenario) return <Redirect to='/scenari' />;

  if (!scenario || !variant /* || !dashboard */) return null;
  return (
    <div id='dashboard' className='megaContent'>
      <div className='container-fluid'>
        <ScenarioHeaderCompact scenario={scenario} variant={variant} />
        {sbShow}
        <Switch>
          <Route exact path={`/scenario/:idScenario/dashboard/variant/:idVariante`}>
            <SummaryPage
              scenario={scenario}
              regions={regions}
              currencyToUse={currencyToUse}
              variant={variant}
              isLoading={isLoading}
              handleStatusBar={handleStatusBar}
            />
          </Route>
          {variant?.analyses?.filter((el) => el.type === 'IA') &&
            (variant?.analyses?.filter((el) => el.type === 'IA')?.[0]?.status === 'Executed' ||
              variant?.analyses?.filter((el) => el.type === 'IA')?.[0]?.status === 'Draft') && (
              <Route path={`/scenario/:idScenario/dashboard/variant/:idVariante/impactanalysis`}>
                <ScenarioImpactAnalysis scenario={scenario} regions={regions} variant={variant} />
              </Route>
            )}
          {variant?.analyses?.filter((el) => el.type === 'SROI') &&
            (variant?.analyses?.filter((el) => el.type === 'SROI')?.[0]?.status === 'Executed' ||
              variant?.analyses?.filter((el) => el.type === 'SROI')?.[0]?.status === 'Draft') && (
              <Route path={`/scenario/:idScenario/dashboard/variant/:idVariante/sroianalyses`}>
                <SroiAnalysesPage
                  scenario={scenario}
                  variant={variant}
                  currencyToUse={currencyToUse}
                />
              </Route>
            )}
          {variant?.analyses?.filter((el) => el.type === 'ESG') &&
            (variant?.analyses?.filter((el) => el.type === 'ESG')?.[0]?.status === 'Executed' ||
              variant?.analyses?.filter((el) => el.type === 'ESG')?.[0]?.status === 'Draft') && (
              <Route path={`/scenario/:idScenario/dashboard/variant/:idVariante/esgrating`}>
                <EsgRatingPage idScenario={idScenario} idVariante={idVariante} />
              </Route>
            )}

          {/* <Redirect
            from={`/scenario/:idScenario/dashboard/*`}
            to={`/scenario/:idScenario/dashboard/variant/:idVariante`}
          /> */}
        </Switch>
      </div>
    </div>
  );
};

export default ScenarioDashboard;
