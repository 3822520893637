import { useState, useRef, useEffect } from 'react';
import { IcoDownloadFile, IcoUploadFile } from '@icons';
import { Button, Spinner } from 'react-bootstrap';
import { propTrans } from '@lib/i18n/index';
import { fetchData } from '@lib/helpers/index';
import { getEntityDocuments, downloadEntityDocuments, uploadEntityDocument } from '@lib/api/index';
import StatusBar from 'components/wizard/StatusBar';

const GenericDownloadUploadItems = ({ title, disabled, corporatequestionnaireId, answerId }) => {
  const [data, setData] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGetLoading, setIsGetLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const inputRef = useRef(null);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const openFileUpload = () => {
    inputRef.current.click();
  };
  const downloadFile = (blob, filename) => {
    const fileUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleDownload = async (documentId, data) => {
    setIsDownloadLoading(true);
    setError(null);
    const downloadResponse = await downloadEntityDocuments(documentId);
    if (downloadResponse.ok === true) {
      const blob = await downloadResponse.blob();
      console.log('blob', data);
      const contentType = downloadResponse.headers.get('Content-Type');
      let filename = 'downloaded_file';
      if (data.fileName) {
        filename = data.fileName;
      }
      const file = new File([blob], filename, { type: contentType });

      downloadFile(file, filename);
    } else if (!downloadResponse.ok || downloadResponse.status !== 200) {
      setError(downloadResponse.message || 'Something went wrong');
    }
    setIsDownloadLoading(false);
  };

  const handleFileUpload = async (selectedFileChange) => {
    if (!selectedFileChange) return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await uploadEntityDocument(
        'CorporateQuestionnaire',
        corporatequestionnaireId,
        answerId,
        formData
      );
      if (response.status) {
        setRefresh(true);
        setIsLoading(false);
      } else {
        setError(response.message || 'Something went wrong');
      }
    } catch (error) {
      console.error('Error uploading file', error);
    }
    setIsLoading(false);
    setFile(null);
  };

  useEffect(() => {
    if (file) {
      handleFileUpload(file);
    }
  }, [file]);

  useEffect(() => {
    if (!corporatequestionnaireId) return;
    fetchData(
      getEntityDocuments,
      setData,
      null,
      ['CorporateQuestionnaire', corporatequestionnaireId, answerId],
      null,
      setError,
      setIsGetLoading
    );
  }, [corporatequestionnaireId, answerId]);
  useEffect(() => {
    if (!refresh || !corporatequestionnaireId) return;
    fetchData(
      getEntityDocuments,
      setData,
      null,
      ['CorporateQuestionnaire', corporatequestionnaireId, answerId],
      null,
      setError,
      setIsGetLoading
    );
    setRefresh(false);
  }, [refresh, corporatequestionnaireId, answerId, setRefresh]);
  return (
    <div>
      <div className='d-flex justify-content-start align-items-center mr-4'>
        {!!title && (
          <p
            className='mb-1 p-0'
            style={{
              whiteSpace: 'nowrap',
              fontSize: '.9rem',
              color: disabled ? 'grey' : 'inherit',
            }}>
            {title}:
          </p>
        )}

        <div className='d-flex justify-content-start align-items-center ml-3'>
          {isLoading ? (
            <Spinner animation='border' role='status' size='md' />
          ) : (
            <>
              <Button
                variant='link'
                disabled={disabled || isLoading}
                title={propTrans({ prop: 'A0579' })}
                onClick={() => openFileUpload()}>
                <IcoUploadFile className='second-color ico-small download-upload' />
              </Button>
              <input
                type='file'
                id='file-input-dat'
                ref={inputRef}
                onChange={handleFileChange}
                disabled={disabled || isGetLoading}
                style={{ display: 'none' }}
              />
              <label
                style={{ display: 'none' }}
                className='file-input-dat'
                htmlFor='file-input-dat'></label>
            </>
          )}
          <div className='d-flex'>
            {isDownloadLoading ? (
              <Spinner animation='border' role='status' size='md' className='ml-2' />
            ) : (
              <>
                <Button
                  variant='link'
                  title='Download file'
                  onClick={() => handleDownload(data?.[0].id, data?.[0])}
                  disabled={disabled || isGetLoading || !data?.[0]?.id}
                  className='ml-3'>
                  <IcoDownloadFile className='second-color ico-small download-upload' />
                </Button>
                {data && (
                  <p className='m-0 p-0' style={{ fontSize: '.9rem' }}>
                    {data?.[0]?.name}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {error && <StatusBar status={'error'} message={error} timeout={false} />}
    </div>
  );
};

export default GenericDownloadUploadItems;
