import React, { useContext, useState, useEffect, useCallback, useRef, Fragment } from 'react';
import {
  Button,
  FormControl,
  ButtonToolbar,
  InputGroup,
  Modal,
  Form,
  Col,
  Row,
} from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Trans } from '@lib/i18n';
import { IcoPlus, Lens } from '@icons';
import {
  getOrganizations,
  getTypes,
  // getClasses,
  getClassesDistinct,
  getOEDatasets,
  postCreateOrganization,
  getCompaniesContracts,
  postCompaniesContracts,
  putCompaniesContracts,
} from '@lib/api';
import StatusBar from 'components/wizard/StatusBar';
import ImageBase64Converter from 'components/adminManagement/imageBase64Converter';
import { LogoContext } from 'components/LogoContext';
import CompaniesWrapper from 'components/CompaniesManagement/CompaniesWrapper';
import { deleteOrganization, getAnalyses, putEditOrganization } from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
import FilterDemo from './PickList/PickList';

const initialState = {
  name: '',
  vat: '',
  address: '',
  legalRepresentative: '',
  email: '',
  pec: '',
  attorney: '',
  companyTypeId: '',
  image: '',
  // sam: [],
  classes: [], //aggiungere in API
  datasets: [],
  companyAnalyses: [],
};

const contractsInitialState = {
  companyId: '',
  contractorId: '',
  canAddCompanies: 0,
  url: '',
  domain: '',
  contractStartDate: null,
  contractEndDate: null,
};
const transformValues = (object, property) => {
  return object[property] && Object.values(object[property]).length > 0
    ? object[property].map((data) => data.value)
    : [];
};
const transformAnalysisValues = (object, property) => {
  return object[property] && Object.values(object[property]).length > 0
    ? object[property].map((data) => data.code)
    : [];
};
const transformClassValues = (object, property) => {
  return object[property] && Object.values(object[property]).length > 0
    ? object[property].map((data) => data.id)
    : [];
};

const OrganizationsManagement = () => {
  const { setLogo } = useContext(LogoContext);
  const [organizations, setOrganizations] = useState([]);
  const [newOrganization, setNewOrganization] = useState(initialState);
  const [newOrganizationContract, setNewOrganizationContract] = useState(contractsInitialState);
  const [types, setTypes] = useState([]);
  const [optionsType, setOptionsType] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  // const [optionsClasses, setOptionsClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  // const [optionsDatasets, setOptionsDatasets] = useState([]);
  const [selectedDatasets, setSelectedDatasets] = useState([]);
  // const [optionsAnalyses, setOptionsAnalyses] = useState([]);
  const [selectedAnalyses, setSelectedAnalyses] = useState([]);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [showModalNew, setShowModalNew] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [refresh, setRefresh] = useState(false);
  const [user, setUser] = useState(null);
  const [filteredOrganizations, setFilteredOrganizations] = useState(organizations);
  const [deleteId, setDeleteId] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [sbDeleteShow, setSbDeleteShow] = useState(null);
  const [pickClasses, setPickClasses] = useState([]);
  const [pickDatasets, setPickDatasets] = useState([]);
  const [pickAnalysesCodes, setPickAnalysesCodes] = useState([]);
  const debounceTimer = useRef(null);
  // const animatedComponents = makeAnimated();
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
  // const urlRegex = /^www\.[a-zA-Z0-9-]+\.externalytics\.com$/;
  const handleCloseModal = (setShowModal) => setShowModal(false);
  const handleShowModal = (setShowModal) => {
    resetForm();
    setShowModal(true);
  };

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };
  const handleDeleteStatusBar = (status, message, timeout) => {
    setSbDeleteShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const resetForm = () => {
    setNewOrganization(initialState);
    setNewOrganizationContract(contractsInitialState);
    setSbShow(<></>);
    setSelectedClass([]);
    setSelectedDatasets([]);
    setSelectedType([]);
    setSelectedAnalyses([]);
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')));
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        const [typesResult, classesDistinctResult, datasetsResults, analysesResults] =
          await Promise.all([
            getTypes().catch((error) => {
              console.log('error', error);
              return { message: 'Something went wrong' };
            }),
            getClassesDistinct().catch((error) => {
              console.log('error', error);
              return { message: 'Something went wrong' };
            }),
            getOEDatasets().catch((error) => {
              console.log('error', error);
              return { message: 'Something went wrong' };
            }),
            getAnalyses().catch((error) => {
              console.log('error', error);
              return { message: 'Something went wrong' };
            }),
          ]);
        if (!abortController.signal.aborted) {
          setTypes(typesResult.data);
          setOptionsType(
            typesResult.data.map((type) => ({
              value: type.id,
              label: type.name,
            }))
          );
          setPickClasses(classesDistinctResult?.data);
          // setOptionsClasses(
          //   classesDistinctResult?.data
          //     .map((retrClass) => ({
          //       value: retrClass.id,
          //       label: retrClass.name,
          //     }))
          //     .sort((a, b) => a.label.localeCompare(b.label))
          // );
          setPickDatasets(datasetsResults?.data);
          // setOptionsDatasets(
          //   datasetsResults.data
          //     .map((dataset) => ({
          //       value: dataset.id,
          //       label: dataset.name,
          //     }))
          //     .sort((a, b) => a.label.localeCompare(b.label))
          // );
          setPickAnalysesCodes(analysesResults.data);
          // setOptionsAnalyses(
          //   analysesResults.data
          //     .filter((analysis) => analysis.active)
          //     .map((analysis) => ({
          //       value: analysis.code,
          //       label: analysis.name,
          //     }))
          //     .sort((a, b) => a.label.localeCompare(b.label))
          // );
        }
      } catch (error) {
        console.error('ERROR', error);
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    };
  }, []);

  const transformContractValues = (data, setState) => {
    return setState(data.length > 0 ? data[0] : contractsInitialState);
  };
  useEffect(() => {
    if (newOrganization.id) {
      fetchData(
        getCompaniesContracts,
        setNewOrganizationContract,
        transformContractValues,
        [newOrganization.id],
        handleStatusBar
      );
    }
  }, [newOrganization.id]);

  useEffect(() => {
    if (refresh) {
      getOrganizations().then((retrievedOrganizations) => {
        if (retrievedOrganizations.status === true) {
          setOrganizations(retrievedOrganizations.data);

          setLogo(
            retrievedOrganizations?.data
              .filter((org) => org.id === user.idOrganization)
              .map((filteredOrg) => {
                return filteredOrg.image;
              })
              .join()
          );
        } else {
          handleStatusBar('error', <Trans code='A0613' />, false);
        }
      });

      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    getOrganizations().then((retrievedOrganizations) => {
      if (retrievedOrganizations.status === true) {
        setOrganizations(retrievedOrganizations.data);
      } else {
        handleStatusBar('error', <Trans code='A0613' />, false);
      }
    });
  }, []);

  const onChange = (e, fieldname, setSelect) => {
    if (fieldname === 'image') {
      const reader = new FileReader();

      reader.onload = () => {
        const base64Image = reader.result;

        setNewOrganization((prevOrganization) => ({
          ...prevOrganization,
          [fieldname]: base64Image,
        }));
      };

      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    } else if (fieldname === 'companyAnalyses') {
      const { id, checked } = e.target;
      console.log('id', id, 'checked', checked);
      setSelect((prev) => {
        if (checked) {
          // Add the new element to the state
          return [...prev, { code: id }];
        } else {
          // Remove the element from the state
          return prev.filter((el) => el.code !== id);
        }
      });
    } else {
      if (setSelect) {
        setSelect(e ?? []);

        setNewOrganization((prevOrganization) => ({
          ...prevOrganization,
          [fieldname]: e ? e.value ?? e : [],
        }));
      } else {
        setNewOrganization((prevOrganization) => ({
          ...prevOrganization,
          [e.target.name]: e.target.value,
        }));
      }
    }
  };

  useEffect(() => {
    setNewOrganization((prevOrganization) => ({
      ...prevOrganization,
      classes: selectedClass,
    }));
  }, [selectedClass]);

  useEffect(() => {
    setNewOrganization((prevOrganization) => ({
      ...prevOrganization,
      datasets: selectedDatasets,
    }));
  }, [selectedDatasets]);
  useEffect(() => {
    setNewOrganization((prevOrganization) => ({
      ...prevOrganization,
      companyAnalyses: selectedAnalyses,
    }));
  }, [selectedAnalyses]);

  const onChangeContracts = (e, fieldname) => {
    console.log('onChange e', e);
    if (fieldname === 'canAddCompanies') {
      const isChecked = e.target.checked;
      setNewOrganizationContract((prevOrganizationContract) => ({
        ...prevOrganizationContract,
        [fieldname]: isChecked ? 1 : 0,
      }));
    } else if (fieldname === 'domain') {
      setNewOrganizationContract((prevOrganizationContract) => ({
        ...prevOrganizationContract,
        url: `${e.target.value}.externalytics.com`,
        [fieldname]: e.target.value,
      }));
    } else {
      setNewOrganizationContract((prevOrganizationContract) => ({
        ...prevOrganizationContract,
        [e.target.name]: e.target.value,
      }));
    }
  };

  useEffect(() => {
    if (organizations.length > 0) {
      setFilteredOrganizations(organizations);
    }
  }, [organizations]);

  const onFilterTextBoxChanged = useCallback(
    (e) => {
      const searchText = e.target.value.toLowerCase();

      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }

      debounceTimer.current = setTimeout(() => {
        if (searchText === '') {
          setFilteredOrganizations(organizations);
        } else {
          const filtered = organizations.filter((org) =>
            org.name.toLowerCase().includes(searchText)
          );
          setFilteredOrganizations(filtered);
        }
      }, 300);
    },
    [organizations]
  );

  const handleNewOrganization = async (e) => {
    e?.preventDefault();

    setEmailIsInvalid(false);
    setSbShow(<></>);
    console.log('newOrganizationContract', newOrganizationContract);
    const { restOrganization, pec } = extractOrganizationDetails(newOrganization);
    const { domain, url } = extractContractDetails(newOrganizationContract);

    if (!isOrganizationFilled(restOrganization)) {
      handleStatusBar('error', <Trans code='A0522' />, false);
      return;
    }

    if (pec && !isValidEmail(pec)) {
      setEmailIsInvalid(true);
      handleStatusBar('error', <Trans code='A0739' />, false);
      return;
    }

    // if ((url === '' || !urlRegex.test(url)) && newOrganization.id !== user.idOrganization) {
    //   handleStatusBar('error', 'Errore url', false);
    //   return;
    // }
    if (user && user.idOrganization === 1 && (!domain || !url)) {
      handleStatusBar('error', <Trans code='A0522' />, false);
      return;
    }
    prepareOrganizationData(newOrganization);

    try {
      const createResult = newOrganization.id
        ? await putEditOrganization(newOrganization, newOrganization.id)
        : await postCreateOrganization(newOrganization);

      if (createResult.status === true) {
        handleResponse(createResult);
      }
    } catch (error) {
      handleStatusBar('error', error.message || <Trans code='A0521' />, false);
    }
  };

  const extractOrganizationDetails = (organization) => {
    const {
      address,
      pec: localPec,
      legalRepresentative,
      attorney,
      companyTypeId,
      image,
      classes,
      datasets,
      type,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      teams,
      companyAnalyses,
      dependsOn,
      ...rest
    } = organization;

    return { restOrganization: rest, pec: localPec };
  };
  const extractContractDetails = (contract) => {
    const { canAddCompanies, url, domain, contractStartDate, contractEndDate, ...rest } = contract;

    return { restContract: rest, url: url, domain: domain };
  };

  const isOrganizationFilled = (organization) => {
    return Object.values(organization).every((prop) => prop !== '' && prop != null);
  };

  const isValidEmail = (email) => {
    return emailRegex.test(email);
  };

  const prepareOrganizationData = (organization) => {
    organization.classes = transformClassValues(organization, 'classes');
    organization.datasets = transformClassValues(organization, 'datasets');
    organization.companyAnalyses = transformAnalysisValues(organization, 'companyAnalyses');
  };

  const handleContracts = async (createResult) => {
    const newOrganizationContractForPost = {
      ...newOrganizationContract,
      companyId: user.idOrganization,
      contractorId: createResult.data.id,
    };
    const createContractResult = newOrganizationContract.id
      ? await putCompaniesContracts(
          createResult?.data?.id,
          newOrganizationContract?.id,
          newOrganizationContract,
          newOrganization.id
        )
      : await postCompaniesContracts(createResult?.data?.id, newOrganizationContractForPost);
    return createContractResult;
  };

  const handleResponse = async (createResult) => {
    let createdResulsts = createResult;
    if (createResult.status === true && newOrganization?.id !== user?.idOrganization) {
      createdResulsts = await handleContracts(createResult);
    }
    console.log('createdResulsts', createdResulsts);
    if (createdResulsts.status === true) {
      handleStatusBar('success', <Trans code='A0657' />, true);

      setTimeout(() => {
        setSbShow(null);
        setShowModalNew(false);
        setRefresh(true);
      }, 2000);
    } else {
      console.log('test', createResult.message);
      handleStatusBar('error', createResult.message || <Trans code='A0521' />, false);
    }
  };

  const handlePopulateOrganization = (organization) => {
    // Extract IDs from the organization
    const classIds = organization?.classes?.map((orgClass) => orgClass.classId);
    const datasetIds = organization?.datasets?.map((orgClass) => orgClass.datasetId);
    const analysesCodes = organization?.companyAnalyses?.map((analysis) => analysis.analysisCode);
    // Filter options based on the extracted IDs
    const selectedType = optionsType?.find((type) => type.value === organization.companyTypeId);
    const selectedClasses = pickClasses?.filter((retrClass) => classIds?.includes(retrClass.id));
    const selectedDatasets = pickDatasets?.filter((dataset) => datasetIds?.includes(dataset.id));
    const selectedAnalyses = pickAnalysesCodes?.filter((analysis) =>
      analysesCodes?.includes(analysis.code)
    );
    // Set the state with the filtered options
    setSelectedType(selectedType ? [selectedType] : []);
    setSelectedClass(selectedClasses || []);
    setSelectedDatasets(selectedDatasets || []);
    setSelectedAnalyses(selectedAnalyses || []);
    // Update the organization state
    setNewOrganization({
      ...organization,
      datasets: selectedDatasets,
      classes: selectedClasses,
      companyAnalyses: selectedAnalyses,
    });
  };

  const handleDelete = async (id) => {
    //console.log('userID', userID);
    if (!id) return;
    const deleteResult = await deleteOrganization(id);

    if (deleteResult.status === true) {
      handleDeleteStatusBar('success', <Trans code='A0749' />, true);

      setTimeout(() => {
        setShowModalDelete(false);
        setDeleteId(null);
        setSbDeleteShow(null);
        setRefresh(true);
      }, 2000);
    } else {
      handleDeleteStatusBar('error', <Trans code='A0750' />, false);
    }
  };

  if (parseInt(JSON.parse(localStorage.getItem('user'))['role']) !== 1) {
    return (
      <div id='scenari' className='megaContent'>
        <div className='container-fluid'>
          <div className='row'>
            <Trans code='A0597' />
            {/* USER NOT AUTHORIZED */}
          </div>
        </div>
      </div>
    );
  }
  console.log('ELEMENT');
  return (
    <div id='organizations' className='megaContent'>
      <div className='container-fluid'>
        <div className='row d-flex align-items-center mb-3'>
          <div className='col-5'>
            <h5 className='title-simple-box mb-0'>
              <Trans code='A0741' />
            </h5>
          </div>
          <div className='col-7 text-right'>
            <ButtonToolbar className='justify-content-end'>
              {(organizations?.filter((org) => org.id === user?.idOrganization)?.[0]?.dependsOn ===
                1 ||
                user?.idOrganization === 1) && (
                <Button
                  variant='info add-organization'
                  onClick={() => handleShowModal(setShowModalNew)}>
                  <div className='row align-items-center py-1 px-3'>
                    <IcoPlus className='light-color ico-small plus mr-2' />
                    <span className='d-md-inline'>
                      <Trans code='A0742' />
                    </span>
                  </div>
                </Button>
              )}

              <InputGroup className='search-table align-items-center bg-white'>
                <FormControl
                  type='text'
                  id='filter-text-box'
                  placeholder='Search...'
                  className='d-none d-md-block '
                  onInput={(e) => onFilterTextBoxChanged(e)}
                />
                <Lens />
              </InputGroup>
            </ButtonToolbar>
          </div>
        </div>
        <CompaniesWrapper
          data={organizations?.filter((org) => org.id === user?.idOrganization)}
          setShow={setShowModalNew}
          setNewOrganization={handlePopulateOrganization}
          title='La mia Organizzazione'
          isSelectable={false}
          isUserCompany={true}
          isOe={user?.idOrganization === 1}
        />
        <CompaniesWrapper
          data={filteredOrganizations?.filter((org) => org.id !== user?.idOrganization)}
          setShow={setShowModalNew}
          setNewOrganization={handlePopulateOrganization}
          title='Organizzazioni contrattualizzate'
          userOrganization={
            organizations?.filter((org) => org.id === user?.idOrganization)[0]?.name
          }
          showModalDelete={showModalDelete}
          setShowModalDelete={setShowModalDelete}
          handleDelete={handleDelete}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
          isOe={user?.idOrganization === 1}
          userCompanyId={user?.idOrganization}
          isThirdLevel={true}
        />
      </div>

      <Modal
        size='xl'
        show={showModalNew}
        onHide={() => handleCloseModal(setShowModalNew)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title className='px-2'>
            <Trans code='A0743' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleNewOrganization} id='form-admin-organization'>
            <h2>
              <Trans code='A0836' />
            </h2>
            <Row className='mb-3'>
              <Form.Group as={Col} className='col-6 px-4' controlId='image'>
                <Form.Label className='mb-3'>Logo</Form.Label>
                <Form.Control
                  type='file'
                  name='image'
                  // defaultValue={newOrganization?.image}
                  onChange={(e) => onChange(e, 'image')}
                />
              </Form.Group>
              {newOrganization?.image && newOrganization?.image?.length > 0 && (
                <ImageBase64Converter
                  base64Image={newOrganization?.image}
                  className={'organization-logo'}
                />
              )}
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='name'>
                <Form.Label className='mb-3'>
                  <Trans code='A0744' />
                  &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='name'
                  defaultValue={newOrganization.name}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='vat'>
                <Form.Label className='mb-3'>
                  <Trans code='A0806' />
                  &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='vat'
                  defaultValue={newOrganization.vat}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='legalRepresentative'>
                <Form.Label className='mb-3'>
                  <Trans code='A0746' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='legalRepresentative'
                  defaultValue={newOrganization.legalRepresentative}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
              {/* <Form.Group as={Col} className='px-4' controlId='cognomeResponsabile'>
                <Form.Label className='mb-3'>
                  <Trans code='A0747' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='cognomeResponsabile'
                  defaultValue={newOrganization.cognomeResponsabile}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group> */}

              <Form.Group as={Col} className='px-4' controlId='email'>
                <Form.Label className='mb-3'>
                  <Trans code='A0748' />
                  &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='email'
                  defaultValue={newOrganization.email}
                  onChange={(e) => onChange(e)}
                  isInvalid={emailIsInvalid}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='pec'>
                <Form.Label className='mb-3'>
                  <Trans code='A0808' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='pec'
                  defaultValue={newOrganization.pec}
                  onChange={(e) => onChange(e)}
                  isInvalid={emailIsInvalid}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='attorney'>
                <Form.Label className='mb-3'>
                  <Trans code='A0805' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='attorney'
                  defaultValue={newOrganization.attorney}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='address'>
                <Form.Label className='mb-3'>
                  <Trans code='A0745' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='address'
                  defaultValue={newOrganization.address}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='companyTypeId'>
                <Form.Label className='mb-3 mr-4'>
                  <Trans code='A0639' />
                </Form.Label>
                <Select
                  name='companyTypeId'
                  value={selectedType}
                  options={optionsType}
                  onChange={(e) => onChange(e, 'companyTypeId', setSelectedType)}
                />
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              {/* <Form.Group as={Col} className='px-4' controlId='classes'>
                <Form.Label className='mb-3 mr-4'>
                  <Trans code='A0376' />
                </Form.Label>
                <Select
                  id='multiselect-class'
                  value={selectedClass}
                  closeMenuOnSelect={false}
                  options={optionsClasses}
                  components={animatedComponents}
                  isMulti
                  isClearable
                  onChange={(e) => onChange(e, 'classes', setSelectedClass)}
                />
              </Form.Group> */}
              <Form.Group as={Col} className='px-4' controlId='classes'>
                <Form.Label className='mb-3 mr-4'>
                  <Trans code='A0376' />
                </Form.Label>
                <FilterDemo
                  targets={selectedClass}
                  id={'classPick'}
                  sources={() => {
                    const newOrgClassIds = selectedClass?.map((c) => c.id);
                    return pickClasses.filter((el) => !newOrgClassIds.includes(el.id));
                  }}
                  setElements={setSelectedClass}
                  sourceHeader={<Trans code='A0839' />}
                  targetHeader={<Trans code='A0838' />}
                />
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='datasets'>
                <Form.Label className='mb-3 mr-4'>
                  <Trans code='A0483' />
                </Form.Label>
                <FilterDemo
                  targets={selectedDatasets}
                  id={'datasetPick'}
                  sources={() => {
                    const newOrgDatasetsIds = selectedDatasets?.map((d) => d.id);
                    return pickDatasets.filter((el) => !newOrgDatasetsIds.includes(el.id));
                  }}
                  setElements={setSelectedDatasets}
                  sourceHeader={<Trans code='A0840' />}
                  targetHeader={<Trans code='A0841' />}
                />
                {/* <Select
                  id='multiselect-dataset'
                  value={selectedDatasets}
                  closeMenuOnSelect={false}
                  options={optionsDatasets}
                  components={animatedComponents}
                  isMulti
                  isClearable
                  onChange={(e) => onChange(e, 'datasets', setSelectedDatasets)}
                /> */}
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='companyAnalyses'>
                <Form.Label className='mb-3 mr-4'>
                  <Trans code='A0564' />
                </Form.Label>
                {/* <Select
                  id='multiselect-analyses'
                  value={selectedAnalyses}
                  closeMenuOnSelect={false}
                  options={optionsAnalyses}
                  components={animatedComponents}
                  isMulti
                  isClearable
                  onChange={(e) => onChange(e, 'companyAnalyses', setSelectedAnalyses)}
                /> */}
                <div style={{ padding: '18px 31.7px' }}>
                  {pickAnalysesCodes
                    ?.filter((an) => Number(an.active))
                    .sort((a, b) => Number(a.position) - Number(b.position))
                    .map((analysis) => (
                      <Form.Check
                        inline
                        key={analysis.code}
                        type='switch'
                        className='custom-switch-md'
                        id={analysis.code}
                        label={<Trans code={analysis.code} />}
                        name='analyses'
                        checked={
                          !!newOrganization?.companyAnalyses?.some(
                            (el) => el.code === analysis.code
                          )
                        }
                        onChange={(e) => onChange(e, 'companyAnalyses', setSelectedAnalyses)}
                      />
                    ))}
                </div>
              </Form.Group>
            </Row>
            {newOrganization?.id !== user?.idOrganization && (
              <>
                <hr className='my-5' style={{ maxWidth: '70%' }} />
                <h2>
                  <Trans code='A0837' />
                </h2>
                {user?.idOrganization === 1 && (
                  <Row className='mb-3'>
                    <Form.Group as={Col} className='px-4' controlId='domain'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0832' />
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='domain'
                        defaultValue={newOrganizationContract.domain}
                        onChange={(e) => onChangeContracts(e, 'domain')}
                        placeholder='e.g. nomeazienda'
                      />
                    </Form.Group>
                    <Form.Group as={Col} className='px-4' controlId='url'>
                      <Form.Label className='mb-3'>
                        URL
                        {/* <Trans code='A0832' /> */}
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        readOnly
                        name='url'
                        value={newOrganizationContract.url}
                        // onChange={(e) => onChangeContracts(e, 'url')}
                        placeholder='e.g. nomeazienda.externalytics.com'
                      />
                    </Form.Group>
                    <Form.Group as={Col} className='px-4' controlId='canAddCompanies'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0835' />
                      </Form.Label>
                      <Form.Control
                        style={{ width: '15px' }}
                        type='checkbox'
                        name='canAddCompanies'
                        checked={newOrganizationContract.canAddCompanies === 1}
                        onChange={(e) => onChangeContracts(e, 'canAddCompanies')}
                      />
                    </Form.Group>
                  </Row>
                )}
                <Row className='mb-3'>
                  <Form.Group as={Col} className='px-4' controlId='contractStartDate'>
                    <Form.Label className='mb-3 mr-4'>
                      <Trans code='A0833' />
                    </Form.Label>
                    <Form.Control
                      type='date'
                      name='contractStartDate'
                      defaultValue={newOrganizationContract.contractStartDate}
                      onChange={(e) => onChangeContracts(e)}
                      // isInvalid={emailIsInvalid}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className='px-4' controlId='contractEndDate'>
                    <Form.Label className='mb-3 mr-4'>
                      <Trans code='A0834' />
                    </Form.Label>
                    <Form.Control
                      type='date'
                      name='contractEndDate'
                      defaultValue={newOrganizationContract.contractEndDate}
                      onChange={(e) => onChangeContracts(e)}
                      // isInvalid={emailIsInvalid}
                    />
                  </Form.Group>
                </Row>
              </>
            )}

            {/* <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='sam'>
                <Form.Label className='mb-3 mr-4'>
                  <Trans code='SAM' />
                </Form.Label>
                <Select
                  id='multiselect-sam'
                  value={selectedSam}
                  closeMenuOnSelect={false}
                  options={optionsSam}
                  components={animatedComponents}
                  isMulti
                  isClearable
                  onChange={(e) => onChange(e, 'sam', setSelectedSam)}
                />
              </Form.Group>
            </Row> */}
          </Form>
          {sbShow}

          <span className='mt-4 px-3'>
            <span className='required-field'>*</span>&nbsp;
            <Trans code='A0503' />
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              resetForm();
              handleCloseModal(setShowModalNew);
            }}>
            <Trans code='F0057' />
          </Button>
          <Button variant='primary' onClick={(e) => handleNewOrganization(e)}>
            {newOrganization?.id ? <Trans code='F00055' /> : <Trans code='A0644' />}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <span>
              <Trans code='A0577' />
            </span>
          </div>
          {sbDeleteShow}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalDelete)}>
            <Trans code='F0057' />
          </Button>
          <Button variant='danger' onClick={() => handleDelete(deleteId)}>
            <Trans code='F00056' />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default OrganizationsManagement;
