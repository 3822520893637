import React, { useRef } from 'react';
import { Trans } from '@lib/i18n';
import { ListGroup, Col, Row, Button } from 'react-bootstrap';
import ListItem from '@components/scenariodashboard/DashboardListItem';
import WaterfallChartGraph from '@components/scenariodashboard/WaterfallChart.js';
import useResizeListener from '@lib/hooks/useResizeListener';
import { formatNumberWithLocale } from '@lib/helpers/index';
import { date } from '@lib/helpers';
import { useHistory, useParams } from 'react-router-dom';
import ExecutionIndicator from './ExecutionIndicator';

const SroiExecuteSummary = ({
  syntheticResults: { benefici, costi, bC, vane, tire, sroi, avgSroi },
  currencyToUse,
  analysis,
  // idScenario,
}) => {
  const history = useHistory();
  const { idScenario = null, idVariante = null } = useParams();
  const waterfallRef = useRef(null);
  const width = useResizeListener(waterfallRef ? waterfallRef : null);

  const data = [
    { propertyName: 'Benefici Generati', propertyValue: benefici },
    { propertyName: 'Spesa Totale', propertyValue: costi },
    { propertyName: 'Valore Economico Netto', propertyValue: bC },
  ];

  const getStyle = (el) => {
    return {
      bullet: {
        backgroundColor:
          el.status === 'Executed' ? '#689689' : el.status === 'Error' ? '#C94723' : '#FFCB47',
      },
      text: {
        color: el.status === 'Executed' ? '#689689' : el.status === 'Error' ? '#C94723' : '#FFCB47',
      },
    };
  };

  return (
    <Col lg={12} className=''>
      <ExecutionIndicator analysis={analysis} analysisUrl={'sroianalyses'} height='50px' />

      {analysis.status === 'Executed' ? (
        <div
          className='d-flex flex-wrap justify-content-around flex-grow-1'
          style={{ position: 'relative', top: '-80px' }}>
          <div
            className='chartBox impactChartTab col-12 col-md d-flex pt-0'
            style={{ alignItems: 'flex-end' }}>
            <ListGroup variant='flush' className='pt-0 flex-grow-1'>
              <ListItem
                listTitle={'A0754'}
                listValue={formatNumberWithLocale(benefici)}
                sign={''}
              />
              <ListItem listTitle={'A0755'} listValue={formatNumberWithLocale(costi)} sign={''} />
              <ListItem listTitle={'ENPV'} listValue={formatNumberWithLocale(vane)} sign={''} />
              <ListItem
                listTitle={'EIRR'}
                listValue={formatNumberWithLocale(tire * 100)}
                sign='&#37;'
              />
            </ListGroup>
          </div>
          <div
            className='chartBox impactChartTab d-flex align-items-flex-end col-12 col-md pt-0'
            style={{ alignItems: 'flex-end' }}>
            <ListGroup variant='flush' className='pt-0 flex-grow-1'>
              <ListItem listTitle={'SROI'} listValue={formatNumberWithLocale(sroi, true)} />
              <ListItem listTitle={'A0761'} listValue={formatNumberWithLocale(avgSroi, true)} />
            </ListGroup>
          </div>
          <div
            ref={waterfallRef}
            className='chartBox impactChartTab flex-grow-1 col-12 col-md waterfallBox pt-0'>
            <WaterfallChartGraph data={data} height={250} width={width} sign={''} />
          </div>
        </div>
      ) : null}
    </Col>
  );
};

export default SroiExecuteSummary;
