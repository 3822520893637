import React from 'react';

import { Trans } from '@lib/i18n/index';
import { Card } from 'react-bootstrap';
import EsgChartsComponent from './esgChartsComponent';
const EsgChartsMainCard = ({ data, category, langCode, icon, subCategories }) => {
  console.log('langcode', langCode);
  return (
    <Card className='w-100' style={{ flex: '1 1 0', minWidth: '350px' }}>
      <Card.Body>
        {icon}
        <Card.Title>
          <Trans code={langCode} />
        </Card.Title>
        <EsgChartsComponent
          data={data}
          category={category}
          subCategories={subCategories}
          langCode={langCode}
        />
      </Card.Body>
    </Card>
  );
};

export default EsgChartsMainCard;
