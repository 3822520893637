import moment from 'moment';
import i18n from '@lib/i18n';
import Currency from '@lib/currency';

const config = {
  dateFormat: 'DD/MM/Y',
};

const formatters = {
  currency: new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: Currency.currency,
  }),
  rounder: new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }),
  rounderNoDecimals: new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }),
  percent: new Intl.NumberFormat(i18n.language, {
    style: 'percent',
    unit: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }),
};

i18n.on('languageChanged', () => {
  formatters.currency = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: Currency.currency,
  });
  formatters.rounder = new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  formatters.rounderNoDecimals = new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  formatters.percent = new Intl.NumberFormat(i18n.language, {
    style: 'percent',
    unit: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  // eslint-disable-next-line default-case
  switch (i18n.language) {
    case 'it':
    case 'es':
      config.dateFormat = 'DD/MM/Y';
      break;
    case 'en':
      config.dateFormat = 'MM/DD/Y';
      break;
  }
});

Currency.on('currencyChanged', () => {
  formatters.currency = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: Currency.currency,
  });
});

export const currency = (value) => formatters.currency.format(value);
export const rounder = (value) => formatters.rounder.format(value);
export const rounderNoDecimals = (value) => formatters.rounderNoDecimals.format(value);
export const percent = (value) => formatters.percent.format(value / 100);
export const date = (value) => moment(value).format(config.dateFormat);
export const chartColors = [
  '#C2C923',
  '#08A2AA',
  '#CB1B4A',
  '#FFB317',
  '#053D58',
  '#378288',
  '#5BCFD4',
];
export const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);

export const formatNumberWithLocale = (value, dec = false) => {
  if (!value) return '- ';
  if (dec) return rounder(value);
  return rounderNoDecimals(value);
};

export const listResizer = (containerIDs) => {
  const allListItems = [];

  containerIDs.forEach((containerId) => {
    const containerSelector = `#${containerId}`;
    const listItems = Array.from(
      document.querySelectorAll(`${containerSelector} .list-group-item`)
    );
    if (listItems.length > 0) {
      allListItems.push(...listItems);
      return;
    } else return;
  });

  let maxHeight = 0;
  let elapsedTime = 0; // Tempo trascorso in millisecondi
  console.log('listItem', allListItems);
  allListItems.forEach((listItem) => {
    listItem.style.height = 'auto';

    if (listItem.offsetHeight > maxHeight) {
      maxHeight = listItem.offsetHeight;
    }
  });
  console.log('listItem', maxHeight);
  if (maxHeight === 0) {
    const interval = setInterval(() => {
      elapsedTime += 100; // Qui aumento il tempo trascorso di 100 millisecondi
      if (elapsedTime >= 10000) {
        // 10 secondi (10000 millisecondi)
        clearInterval(interval); // Abort della funzione
        console.log('Timeout raggiunto. Funzione dismessa.');
        return;
      }

      const allListItems = Array.from(document.getElementsByClassName('list-group-item'));
      let newMaxHeight = 0;

      allListItems.forEach((listItem) => {
        listItem.style.height = 'auto';
        if (listItem.offsetHeight > newMaxHeight) {
          newMaxHeight = listItem.offsetHeight;
        }
      });

      if (newMaxHeight !== 0) {
        clearInterval(interval); // Fermo l'intervallo se maxHeight è maggiore di 0
        maxHeight = newMaxHeight;
        listResizer(containerIDs);
      }
    }, 100);
  } else {
    const listGroups = {};
    let isSameHeight = true; // Flag per indicare che ogni container abbia lo stesso numero di listItems

    // Raggruppo tutti i listItems in base al loro container
    allListItems.forEach((listItem) => {
      const containerId = listItem.parentNode.id;
      if (!listGroups[containerId]) {
        listGroups[containerId] = [];
      }
      listGroups[containerId].push(listItem);
    });

    // Trovo il container con l'altezza più ampia.
    let maxContainerHeight = 0;
    let maxContainerId = null;
    for (const containerId in listGroups) {
      const containerHeight = listGroups[containerId].reduce((totalHeight, item) => {
        return totalHeight + item.offsetHeight;
      }, 0);
      if (containerHeight > maxContainerHeight) {
        maxContainerHeight = containerHeight;
        maxContainerId = containerId;
      }
    }

    // Check se tutti i container hanno la stessa quantità di listItems
    const numItemsInFirstContainer = listGroups[Object.keys(listGroups)[0]].length;
    for (const containerId in listGroups) {
      if (listGroups[containerId].length !== numItemsInFirstContainer) {
        isSameHeight = false;
        break;
      }
    }

    for (const containerId in listGroups) {
      if (!containerId) return;
      const container = document.getElementById(containerId);

      if (isSameHeight) {
        // Tutti i container hanno pari quantità di listItems
        const listItemHeight = maxContainerHeight / numItemsInFirstContainer;
        listGroups[containerId].forEach((listItem) => {
          listItem.style.height = `${listItemHeight}px`;
        });
        container.style.height = `${maxContainerHeight}px`;
      } else {
        // Almeno un container ha una quantità di listItems maggiore
        if (containerId !== maxContainerId) {
          const listItemHeight = maxContainerHeight / listGroups[containerId].length;
          listGroups[containerId].forEach((listItem) => {
            listItem.style.height = `${listItem.offsetHeight + listItemHeight}px`;
          });
        }
        container.style.height = `${maxContainerHeight}px`;
      }
    }
  }
};

// ATTENZIONE: un minimo di 2 colori deve essere fornito in input
export const generateColors = (colors, numberOfColors) => {
  // La funzione restituisce un array di colori che inizia con i colori forniti, e termina con colori generati.
  const interpolateColor = (color1, color2, ratio) => {
    // Le funzioni qui sotto convertono un colore esadecimale in RGB
    const hexToR = (hex) => parseInt(hex.slice(1, 3), 16);
    const hexToG = (hex) => parseInt(hex.slice(3, 5), 16);
    const hexToB = (hex) => parseInt(hex.slice(5, 7), 16);

    // Converte un RGB in esadecimale
    const r1 = hexToR(color1);
    const g1 = hexToG(color1);
    const b1 = hexToB(color1);
    const r2 = hexToR(color2);
    const g2 = hexToG(color2);
    const b2 = hexToB(color2);

    // Qui in basso viene calcolato il colore intermedio tra due colori in base a un rapporto dato in input (ratio).
    const r = Math.round(r1 + (r2 - r1) * ratio);
    const g = Math.round(g1 + (g2 - g1) * ratio);
    const b = Math.round(b1 + (b2 - b1) * ratio);
    // Restituisce il colore intermedio in esadecimale
    return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
  };

  const generatedColors = [];

  // Qui calcolo quanti colori generare in base al numero di colori che servono in base alla lunghezza dei dati forniti (numberOfColors) e alla lunghezza dell'array di colori fornito in input (colors) meno 1 (perché il primo colore è già presente nell'array fornito in input)
  const gradations = numberOfColors / (colors.length - 1);

  // Qui genero i colori e li aggiungo all'array di colori fornito in input (colors) in base al numero di colori da generare (gradations) e alla lunghezza dell'array di colori fornito in input (colors) meno 1 (perché il primo colore è già presente nell'array fornito in input)
  for (let i = 0; i < colors.length - 1; i++) {
    const color1 = colors[i];
    const color2 = colors[i + 1];
    for (let j = 1; j <= gradations; j++) {
      const ratio = j / (gradations + 1);
      generatedColors.push(interpolateColor(color1, color2, ratio));
    }
  }

  return [...colors, ...generatedColors];
};

export const fetchData = async (
  apiFunction,
  setState = null,
  transformFunction = null,
  queryParams,
  errorHandlingFunction = null,
  setError = null,
  setIsLoading = null
) => {
  if (setIsLoading) setIsLoading(true);
  if (setError) setError(null);
  try {
    const response = await apiFunction(...queryParams);
    if (!response.status) throw new Error(response);
    if (response.status) {
      if (transformFunction && !setState) {
        transformFunction(response.data);
      } else if (transformFunction && setState) {
        transformFunction(response.data, setState);
      } else if (setState && !transformFunction) {
        setState(response.data);
      } else {
        return await response.data;
      }
    }
    if (setIsLoading) setIsLoading(false);
  } catch (error) {
    if (errorHandlingFunction) {
      errorHandlingFunction('error', error.message, false);
    } else {
      setError(error.message);
      // setError(error);
    }
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
};
