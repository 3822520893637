import i18n from '@lib/i18n';
import Currency from '@lib/currency';
//const baseUrl = window.externalyticsApiUrl || 'https://externalytics-api.stage.openeconomics.ovh'

const baseUrl = process.env.REACT_APP_BASE_URL;
const laravelURL = process.env.REACT_APP_LARAVEL_URL;

// const apiUrl = baseUrl + '/v2';

const apiUrl = `${laravelURL}/v3`;
// NEW DEFAULT HEADERS LARAVEL
export const getDefaultHeaders = () => {
  const auth = getAuth();
  const token = localStorage.getItem('token');
  return {
    Authorization: `Bearer ${token}`,
    'Authorization-slim': `Basic ${auth}`,
    Accept: 'application/json',
    'Content-Type': 'application/json;',
    // 'Access-Control-Allow-Headers': 'Authorization, Authorization-slim',
  };
};
export const getLoginHeaders = () => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json;',
  };
};

// OLD DEFAULT HEADERS
// export const getDefaultHeaders = () => {
//   const auth = getAuth();
//   return {
//     Authorization: `Basic ${auth}`,
//     'Content-Type': 'application/json;',
//   };
// };

export const getUploadHeaders = () => {
  const auth = getAuth();
  const token = localStorage.getItem('token');
  return {
    Authorization: `Bearer ${token}`,
    'Authorization-slim': `Basic ${auth}`,
  };
};
export const getDownloadExcelHeaders = () => {
  const auth = getAuth();
  const token = localStorage.getItem('token');
  return {
    Authorization: `Bearer ${token}`,
    'Authorization-slim': `Basic ${auth}`,
    'Content-Type': 'application/vnd.ms-excel',
  };
};
export const getDownloadZipHeaders = () => {
  const auth = getAuth();
  const token = localStorage.getItem('token');
  return {
    Authorization: `Bearer ${token}`,
    'Authorization-slim': `Basic ${auth}`,
    'Content-Type': 'application/zip',
  };
};

const handleErrorResponse = async (response) => {
  let errorMessage;
  try {
    const errorJson = await response.json();
    errorMessage = errorJson.message || 'Something went wrong';
  } catch (jsonError) {
    errorMessage = 'Something went wrong';
  }
  if (response.status === 401) {
    throw new Error(errorMessage);
  } else if (response.status === 500) {
    throw new Error('Internal Server Error');
  } else {
    throw new Error(errorMessage);
  }
};
// const handleErrorResponse = async (response) => {
//   let errorMessage;

//   try {
//     const errorJson = await response.json();
//     errorMessage = errorJson.message || 'Something went wrong';
//   } catch (jsonError) {
//     if (response.status === 401) {
//       // localStorage.removeItem('token');
//       // localStorage.removeItem('user');
//       // const referrer = window.location.pathname;
//       // window.location.href = `/login${referrer !== '/login' ? `?referrer=${referrer}` : ''}`;
//       return new Error(errorMessage);
//     } else if (response.status === 500) {
//       errorMessage = 'Internal Server Error';
//     } else {
//       errorMessage = 'Something went wrong';
//     }
//   }

//   return errorMessage;
// };
const parseResponse = async (response, isDownload) => {
  if (!isDownload) {
    return await response.json();
  } else {
    return response;
  }
};

export const sendRequest = async (
  url,
  method,
  data = null,
  getHeadersFunction = getDefaultHeaders,
  isDownload = false,
  isUpload = false
) => {
  try {
    const params = {
      method: method,
      headers: {
        ...getHeadersFunction(),
      },
    };
    if (data) {
      if (isUpload) {
        params.body = data;
      } else {
        params.body = JSON.stringify(data);
      }
    }
    const response = await fetch(url, params);
    if (!response.ok) {
      const error = await handleErrorResponse(response);
      throw error;
    } else {
      return parseResponse(response, isDownload);
    }
  } catch (err) {
    return err;
  }
};

const credentials = {
  username: localStorage.getItem('external_user') || '',
  password: localStorage.getItem('external_password') || '',
};

const getAuth = () => {
  return btoa(`${credentials.username}:${credentials.password}`);
};

// NEW DEFAULT HEADERS LARAVEL
// export const getDefaultHeaders = () => {
//   const token = localStorage.getItem('token');
//   return {
//     Authorization: `Bearer ${token}`,
//     Accept: 'application/json',
//     'Content-Type': 'application/json;',
//   };
// };

// export const getDefaultHeaders = () => {
//   const auth = getAuth();
//   return {
//     Authorization: `Basic ${auth}`,
//     'Content-Type': 'application/json;',
//   };
// };

// export const getUploadHeaders = () => {
//   const auth = getAuth();
//   return {
//     Authorization: `Basic ${auth}`,
//   };
// };

export const getBaseUrl = (onlyUrl = false) => {
  return onlyUrl ? baseUrl : `${baseUrl}/${Currency.currency}/${i18n.language}`;
};

// export const checkLogin = ({ username, password }) =>
//   fetch(`${baseUrl}/login`, {
//     headers: { Authorization: 'Basic ' + btoa(`${username}:${password}`) },
//   })
//     .then((r) => (r.status < 300 && r.status >= 200 ? 200 : r.status))
//     .then((r) => {
//       if (r === 200) {
//         credentials.username = username;
//         credentials.password = password;
//         localStorage.setItem('external_user', username);
//         localStorage.setItem('external_password', password);
//       }
//       return r;
//     });

// NEW CHECK LOGIN LARAVEL
export const checkLogin = (data) => {
  return sendRequest(`${laravelURL}/login`, 'POST', data, getLoginHeaders);
};
export const checkLoginOtp = (data) => {
  return sendRequest(`${laravelURL}/login-otp`, 'POST', data, getLoginHeaders);
};

export const forgotPassword = (data) => {
  return sendRequest(`${laravelURL}/forgot-password`, 'POST', data, getLoginHeaders);
};

export const validateOtpForgotPassword = (data) => {
  return sendRequest(`${laravelURL}/validate-otp`, 'POST', data, getLoginHeaders);
};

export const resetPassword = (data) => {
  return sendRequest(`${laravelURL}/reset-password`, 'POST', data, getLoginHeaders);
};

export const testCall = (type) => {
  return sendRequest(`${laravelURL}/v3/${type}`, 'GET');
};

export const checkTokenValidity = () => {
  return sendRequest(`${laravelURL}/token/check`, 'GET');
};

export const checkAuth = () =>
  fetch(`${baseUrl}/login`, { headers: { ...getDefaultHeaders() } }).then((r) =>
    r.status < 300 && r.status >= 200 ? 200 : r.status
  );

export const logout = () => {
  localStorage.removeItem('external_user');
  localStorage.removeItem('external_password');
  credentials.username = '';
  credentials.password = '';
  localStorage.removeItem('user');
  localStorage.removeItem('token');
};

// export const getOpenScenari = () => {
//   // return fetch(`${getBaseUrl()}/scenari`, {
//   return fetch(`${baseUrl}/scenari?access=1&publish=1`, {
//     headers: {
//       ...getDefaultHeaders(),
//     },
//   }).then((r) => r.json());
// };
export const getOpenScenari = () => {
  return sendRequest(`${baseUrl}/scenari?access=1&publish=1`, 'GET');
};

export const getOEScenarios = () => {
  return sendRequest(`${apiUrl}/scenarios`, 'GET');
};

export const getOEScenario = (idScenario) => {
  return sendRequest(`${apiUrl}/scenarios/${idScenario}`, 'GET');
};

export const postScenario = (scenario) => {
  return sendRequest(`${apiUrl}/scenarios`, 'POST', scenario);
};

export const cloneScenario = (scenario, id) => {
  return sendRequest(`${apiUrl}/scenario/${id}/clone`, 'POST', scenario);
};

export const putScenario = (scenario) => {
  return sendRequest(`${apiUrl}/scenarios/${scenario.id}`, 'PUT', scenario);
};

export const deleteScenario = (idScenario) => {
  return sendRequest(`${apiUrl}/scenarios/${idScenario}`, 'DELETE');
};

//OLD
export const getScenari = () => {
  return sendRequest(`${baseUrl}/scenari`, 'GET');
};

export const getScenarioMeta = (id) => {
  return id
    ? getScenari().then((r) => r.filter((s) => parseInt(s.idScenario) === parseInt(id)).pop())
    : null;
};

export const getScenario = (idScenario) => {
  return sendRequest(`${getBaseUrl()}/scenari/${idScenario}`, 'GET');
};

export const getVisibilities = () => {
  return sendRequest(`${apiUrl}/oescenviews`, 'GET');
};

export const getScenarioTypologies = () => {
  return sendRequest(`${apiUrl}/oescentypes`, 'GET');
};

export const getFinInputTypes = () => {
  return sendRequest(`${apiUrl}/oefininputtypes`, 'GET');
};

export const getOECurrencies = () => {
  return sendRequest(`${apiUrl}/oecurrencies`, 'GET');
};

export const getOESecDisags = () => {
  return sendRequest(`${apiUrl}/oesecdisags`, 'GET');
};

export const getOEGeoPrecisions = () => {
  return sendRequest(`${apiUrl}/oegeoprecisions`, 'GET');
};

export const getOEGeoExts = () => {
  return sendRequest(`${apiUrl}/oegeoexts`, 'GET');
};

export const getOEDatasetTypes = () => {
  return sendRequest(`${apiUrl}/oedatasettypes`, 'GET');
};

export const getOERegions = () => {
  return sendRequest(`${apiUrl}/oeregions`, 'GET');
};

export const getOEMacrosectors = () => {
  return sendRequest(`${apiUrl}/oemacrocodes`, 'GET');
};

export const getCurrencies = () => {
  return sendRequest(`${baseUrl}/currencies`, 'GET');
};

export const getAnalyses = () => {
  return sendRequest(`${apiUrl}/oeanalysistypes`, 'GET');
};

export const postScenAnalysis = (data, idScenario) => {
  return sendRequest(`${apiUrl}/scenarios/${idScenario}/analyses`, 'POST', data);
};
// export const postScenAnalysis = (data, idScenario, idVariante) => {
//   return sendRequest(
//     `${apiUrl}/scenarios/${idScenario}/variants/${idVariante}/analyses`,
//     'POST',
//     data
//   );
// };

export const putScenAnalysis = (data, idScenario, idAnalysis) => {
  return sendRequest(`${apiUrl}/scenarios/${idScenario}/analyses/${idAnalysis}`, 'PUT', data);
};
// export const putScenAnalysis = (data, idScenario, idVariante, idAnalysis) => {
//   return sendRequest(
//     `${apiUrl}/scenarios/${idScenario}/variants/${idVariante}/analyses/${idAnalysis}`,
//     'PUT',
//     data
//   );
// };

export const delScenAnalysis = (data, idScenario, idAnalysis) => {
  return sendRequest(`${apiUrl}/scenarios/${idScenario}/analyses/${idAnalysis}`, 'DELETE', data);
};
// export const delScenAnalysis = (data, idScenario, idVariante, idAnalysis) => {
//   return sendRequest(
//     `${apiUrl}/scenarios/${idScenario}/variants/${idVariante}/analyses/${idAnalysis}`,
//     'DELETE',
//     data
//   );
// };

//ADDITIONAL INFO
export const putScenAdditionalInfo = (data, idScenario) => {
  return sendRequest(`${apiUrl}/scenarios/${idScenario}/additionalinfo`, 'PUT', data);
};
export const putVariantAdditionalInfo = (data, idScenario, idVariante) => {
  return sendRequest(
    `${apiUrl}/scenarios/${idScenario}/variants/${idVariante}/additionalinfo`,
    'PUT',
    data
  );
};

export const deleteScenAdditionalInfo = (idScenario, idInfo) => {
  return sendRequest(`${apiUrl}/scenarios/${idScenario}/additionalinfo/${idInfo}`, 'DELETE');
};
export const deleteVarAdditionalInfo = (idScenario, idVariante, idInfo) => {
  return sendRequest(
    `${apiUrl}/scenarios/${idScenario}/variants/${idVariante}/additionalinfo/${idInfo}`,
    'DELETE'
  );
};

export const getGeoExtensions = (idOrganization) => {
  return sendRequest(`${apiUrl}/oegeoexts?organization=${idOrganization}`, 'GET');
};

export const getGeoPrecisions = (idOrganization) => {
  return sendRequest(`${apiUrl}/oegeoprecisions?organization=${idOrganization}`, 'GET');
};

export const getDatasetTypes = (idOrganization) => {
  return sendRequest(`${apiUrl}/oedatasettypes?organization=${idOrganization}`, 'GET');
};

export const getCountry = () => {
  return sendRequest(`${baseUrl}/getcountryfm`, 'GET');
};

export const getCountryfm = (idCountry) => {
  return sendRequest(`${getBaseUrl()}/countryfm/Italy`, 'GET');
};

//OLD
export const getAdminClasses = async (value) => {
  return sendRequest(`${baseUrl}/adminGetClasses/${value}`, 'GET');
};

export const getAdminRegion = (value) => {
  return sendRequest(`${baseUrl}/adminGetRegion/${value}`, 'GET');
};

export const getAdminSam = (country, region = null) => {
  if (region == null) {
    return sendRequest(`${baseUrl}/adminGetSam/${country}`, 'GET');
  } else {
    return sendRequest(`${baseUrl}/adminGetSam/${country}/${region}`, 'GET');
  }
};
export const getScenarioSelect = () => {
  return sendRequest(`${baseUrl}/adminGetScenarioSelect`, 'GET');
};

//LANGUAGES
export const getOELanguages = () => {
  return sendRequest(`${apiUrl}/oelanguages`, 'GET');
};

export const getTranslations = () => {
  return sendRequest(`${apiUrl}/language`, 'GET');
};
export const getTranslationsUrl = () => `${apiUrl}/language`;

export const getAuthUser = () => {
  return sendRequest(`${baseUrl}/user`, 'GET');
};

export const getScenariDashboard = () => {
  return sendRequest(`${baseUrl}/scenaridashboard`, 'GET');
};

export const getScenariKpi = (idScenario) => {
  return sendRequest(`${baseUrl}/scenarikpi/${idScenario}`, 'GET');
};

export const postScenariKpi = (data, idScenario) => {
  return sendRequest(`${baseUrl}/scenarikpi/${idScenario}`, 'POST', data);
};

//ADMIN USERS

export const getUsers = () => {
  return sendRequest(`${apiUrl}/users`, 'GET');
};

export const getUser = (userId) => {
  return sendRequest(`${apiUrl}/users/${userId}`, 'GET');
};

export const postCreateUser = (data) => {
  return sendRequest(`${apiUrl}/users`, 'POST', data);
};

export const putEditUser = (data, userId) => {
  return sendRequest(`${apiUrl}/users/${userId}`, 'PUT', data);
};

export const deleteUser = (userId) => {
  return sendRequest(`${apiUrl}/users/${userId}`, 'DELETE');
};

export const getRoles = () => {
  return sendRequest(`${apiUrl}/admin/roles`, 'GET');
};

//ADMIN TYPES
// export const getTypes = () => {
//   return fetch(`${baseUrl}/organizations/type`, {
//     method: 'GET',
//     headers: {
//       ...getDefaultHeaders(),
//     },
//   }).then((r) => r.json());
// };
export const getTypes = () => {
  return sendRequest(`${apiUrl}/companies/type`, 'GET');
};
// export const getTypes = () => {
//   return sendRequest(`${baseUrl}/organizations/type`, 'GET');
// };

//ADMIN SAM
export const getSam = () => {
  return sendRequest(`${baseUrl}/sam`, 'GET');
};

//ADMIN ORGANIZATIONS
//OLD
export const getAdminOrganization = (value) => {
  return sendRequest(`${baseUrl}/adminGetOrganization/${value}`, 'GET');
};
//OLD
// export const getOrganizations = () => {
//   return sendRequest(`${baseUrl}/organizations`, 'GET');
// };
export const getOrganizations = () => {
  return sendRequest(`${apiUrl}/companies`, 'GET');
};

// export const getOrganization = (organizationId) => {
//   return sendRequest(`${baseUrl}/organizations/${organizationId}`, 'GET');
// };
export const getOrganization = (companyId) => {
  return sendRequest(`${apiUrl}/companies/${companyId}`, 'GET');
};

// export const postCreateOrganization = (data) => {
//   return sendRequest(`${baseUrl}/organizations`, 'POST', data);
// };
export const postCreateOrganization = (data) => {
  return sendRequest(`${apiUrl}/companies`, 'POST', data);
};

// export const putEditOrganization = (data, organizationId) => {
//   return sendRequest(`${baseUrl}/organizations/${organizationId}`, 'PUT', data);
// };
export const putEditOrganization = (data, companyId) => {
  return sendRequest(`${apiUrl}/companies/${companyId}`, 'PUT', data);
};

// export const deleteOrganization = (organizationId) => {
//   return sendRequest(`${baseUrl}/organizations/${organizationId}`);
// };
export const deleteOrganization = (companyId) => {
  return sendRequest(`${apiUrl}/companies/${companyId}`, 'DELETE');
};

//CLASSES
export const getClasses = () => {
  return sendRequest(`${apiUrl}/classes`, 'GET');
};

export const getClassesVersions = () => {
  return sendRequest(`${apiUrl}/classes?versions=true`, 'GET');
};

export const getLatestVersionClasses = () => {
  return sendRequest(`${apiUrl}/classes?version=latest`, 'GET');
};

export const getClassesDistinct = () => {
  return sendRequest(`${apiUrl}/classes?distinct=true`, 'GET');
};

export const getClass = (classId) => {
  return sendRequest(`${apiUrl}/classes/${classId}`, 'GET');
};

export const checkBridgeMatrices = (classId) => {
  return sendRequest(`${apiUrl}/classes/${classId}/checkbridgematrices`, 'GET');
};

export const showBridgeMatrices = (expenseItemListId, type, secDisagCode) => {
  return sendRequest(
    `${apiUrl}/bridgematrixvalues/${expenseItemListId}/${type}/${secDisagCode}`,
    'GET'
  );
};

export const downloadBridgeMatrix = (expenseItemListId, type, secDisagCode) => {
  return sendRequest(
    `${apiUrl}/bridgematrixvalues/${expenseItemListId}/${type}/${secDisagCode}?format=xlsx`,
    'GET',
    false,
    getDownloadExcelHeaders,
    true
  );
};

export const uploadBridgeMatrix = (expenseItemListId, type, secDisagCode, formData) => {
  return sendRequest(
    `${apiUrl}/bridgematrixvalues/${expenseItemListId}/${type}/${secDisagCode}`,
    'POST',
    formData,
    getUploadHeaders,
    false,
    true
  );
};

export const postCreateClass = (data) => {
  return sendRequest(`${apiUrl}/classes`, 'POST', data);
};

export const putEditClass = (data, classId) => {
  return sendRequest(`${apiUrl}/classes/${classId}`, 'PUT', data);
};

export const deleteClass = (classId) => {
  return sendRequest(`${apiUrl}/classes/${classId}`, 'DELETE');
};

export const copyClass = (data, classId) => {
  return sendRequest(`${apiUrl}/classes/clone/${classId}`, 'POST', data);
};

export const copyFullClass = (data, classId) => {
  return sendRequest(`${apiUrl}/classes/fullclone/${classId}`, 'POST', data);
};

export const postBridgeMatricesFromClass = (classId, data, secDisagCode) => {
  return sendRequest(`${apiUrl}/classes/${classId}/bridgematrices/${secDisagCode}`, 'POST', data);
};

//NACE SECTORS
export const getNaceSectors = () => {
  return sendRequest(`${apiUrl}/oenacesectors`, 'GET');
};

//SECTORS
export const getSectors = () => {
  return sendRequest(`${apiUrl}/oesectors`, 'GET');
};

//INTERVENT TYPES OF CLASSES
export const getInterventTypes = () => {
  return sendRequest(`${apiUrl}/oeinvtypes`, 'GET');
};

//TAGS
export const getTags = () => {
  return sendRequest(`${apiUrl}/tags`, 'GET');
};

//NEW KPIs
export const getOEKpis = () => {
  return sendRequest(`${apiUrl}/kpis`, 'GET');
};

export const getOEKpi = (kpiId) => {
  return sendRequest(`${apiUrl}/kpis/${kpiId}`, 'GET');
};

export const postCreateOEKpi = (data) => {
  return sendRequest(`${apiUrl}/kpis`, 'POST', data);
};

export const putEditOEKpi = (data, kpiId) => {
  return sendRequest(`${apiUrl}/kpis/${kpiId}`, 'PUT', data);
};

export const deleteOEKpi = (kpiId) => {
  return sendRequest(`${apiUrl}/kpis/${kpiId}`, 'DELETE');
};

export const copyOEKpi = (data, kpiId) => {
  return sendRequest(`${apiUrl}/kpis/clone/${kpiId}`, 'POST', data);
};

export const getOEInterventionModules = () => {
  return sendRequest(`${apiUrl}/invtypesmodule`, 'GET');
};

export const putOEInterventionModules = (data, oenacesector) => {
  return sendRequest(`${apiUrl}/invtypesmodule/${oenacesector}`, 'PUT', data);
};
export const deleteOEInterventionModules = (invTypeCode) => {
  return sendRequest(`${apiUrl}/invtypesmodule/${invTypeCode}`, 'DELETE');
};

//OLD KPIs
export const getKpis = () => {
  return sendRequest(`${baseUrl}/kpi`, 'GET');
};

export const getKpi = (kpiId) => {
  return sendRequest(`${baseUrl}/kpi/${kpiId}`, 'GET');
};

export const postCreateKpi = (data) => {
  return sendRequest(`${baseUrl}/kpi`, 'POST', data);
};

export const putEditKpi = (data, kpiId) => {
  return sendRequest(`${baseUrl}/kpi/${kpiId}`, 'PUT', data);
};

export const deleteKpi = (kpiId) => {
  return sendRequest(`${baseUrl}/kpi/${kpiId}`, 'DELETE');
};

export const copyKpi = (data, kpiId) => {
  return sendRequest(`${baseUrl}/kpi/clone/${kpiId}`, 'POST', data);
};

//TEXT TRANSLATIONS
export const getTextTranslations = () => {
  return sendRequest(`${apiUrl}/translations`, 'GET');
};

export const getTextTranslation = (translationCode) => {
  return sendRequest(`${apiUrl}/translations/${translationCode}`, 'GET');
};

export const postCreateTranslation = (data) => {
  return sendRequest(`${apiUrl}/translations`, 'POST', data);
};

export const putEditTranslation = (data, translationCode) => {
  return sendRequest(`${apiUrl}/translations/${translationCode}`, 'PUT', data);
};

export const deleteTranslation = (translationCode) => {
  return sendRequest(`${apiUrl}/translations/${translationCode}`, 'DELETE');
};

//COUNTRIES
export const getCountries = () => {
  return sendRequest(`${baseUrl}/country`, 'GET');
};

export const getOEDatasets = () => {
  return sendRequest(`${apiUrl}/datasets`, 'GET');
};

export const postOEDatasets = (data) => {
  return sendRequest(`${apiUrl}/datasets`, 'POST', data);
};

export const putOEDataset = (data, datasetId) => {
  return sendRequest(`${apiUrl}/datasets/${datasetId}`, 'PUT', data);
};

export const deleteOEDatasetItem = (id) => {
  return sendRequest(`${apiUrl}/datasets/${id}`, 'DELETE');
};

export const cloneOEDataset = (datasetId) => {
  return sendRequest(`${apiUrl}/datasets/clone/${datasetId}`, 'POST');
};

export const uploadOEExcelDatasetItem = (id, formData) => {
  return sendRequest(
    `${apiUrl}/datasets/${id}/datasetvalues`,
    'POST',
    formData,
    getUploadHeaders,
    false,
    true
  );
};

export const downloadOEExcelDatasetItem = (id) => {
  return sendRequest(
    `${apiUrl}/datasets/${id}/datasetvalues`,
    'GET',
    false,
    getDownloadExcelHeaders,
    true
  );
};

export const downloadOEScenarioItem = (idScenario, idVariante) => {
  return sendRequest(
    `${apiUrl}/scenarios/${idScenario}/variants/${idVariante}/export`,
    'GET',
    false,
    getDownloadExcelHeaders,
    true
  );
};

export const downloadOEScenarioAdditionalInfo = (idScenario, idVariante) => {
  return sendRequest(
    `${apiUrl}/scenarios/${idScenario}/variants/${idVariante}/additionalinfo/download`,
    'GET',
    false,
    getDownloadZipHeaders,
    true
  );
};

// export const downloadOEExcelPefEv = (scenarioId, analysisMode, regionCode) => {
//   return sendRequest(
//     `${apiUrl}/scenarios/${scenarioId}/financialdata/${analysisMode}/${regionCode}?format=xlsx`,
//     'GET',
//     false,
//     getDownloadExcelHeaders,
//     true
//   );
// };

export const downloadOEExcelPefEv = (scenarioId, variantId, analysisMode, regionCode) => {
  return sendRequest(
    `${apiUrl}/scenarios/${scenarioId}/variants/${variantId}/financialdata/${analysisMode}/${regionCode}?format=xlsx`,
    'GET',
    null,
    getDownloadExcelHeaders,
    true
  );
};

// export const uploadOEExcelPefEv = (idscenario, entity, regionCode, formData) => {
//   return sendRequest(
//     `${apiUrl}/scenarios/${idscenario}/financialdata/${entity}/${regionCode}?format=xlsx`,
//     'POST',
//     formData,
//     getUploadHeaders,
//     false,
//     true
//   );
// };
export const uploadOEExcelPefEv = (idscenario, idVariante, entity, regionCode, formData) => {
  return sendRequest(
    `${apiUrl}/scenarios/${idscenario}/variants/${idVariante}/financialdata/${entity}/${regionCode}?format=xlsx`,
    'POST',
    formData,
    getUploadHeaders,
    false,
    true
  );
};

export const getOEGeoDatasets = (geoExtCode, precisionCode, typeCode) => {
  return sendRequest(
    `${apiUrl}/datasets?geoExtCode=${geoExtCode}&precisionCode=${precisionCode}&typeCode=${typeCode}`,
    'GET'
  );
};

export const getDatasetRegions = (datasetId) => {
  return sendRequest(`${apiUrl}/datasets/${datasetId}/regions`, 'GET');
};

export const getMacroimpactScenari = () => {
  return sendRequest(`${apiUrl}/scenaridashboard`, 'GET');
};

// export const postFinancialData = (scenarioId, regionCode) => {
//   return sendRequest(`${apiUrl}/scenarios/${scenarioId}/financialdata`, 'POST', regionCode);
// };
export const postFinancialData = (scenarioId, variantId, regionCode) => {
  return sendRequest(
    `${apiUrl}/scenarios/${scenarioId}/variants/${variantId}/financialdata`,
    'POST',
    regionCode
  );
};

export const deleteFinancialData = (scenarioId, regionCode) => {
  return sendRequest(`${apiUrl}/scenarios/${scenarioId}/financialdata/${regionCode}`, 'DELETE');
};

// export const getFinancialData = (scenarioId, analysisMode, regionCode) => {
//   return sendRequest(
//     `${apiUrl}/scenarios/${scenarioId}/financialdata/${analysisMode}/${regionCode}`,
//     'GET'
//   );
// };

export const getFinancialData = (scenarioId, variantId, analysisMode, regionCode) => {
  return sendRequest(
    `${apiUrl}/scenarios/${scenarioId}/variants/${variantId}/financialdata/${analysisMode}/${regionCode}`,
    'GET'
  );
};
//SCENARIOS DATASET SELECTION
export const getOEScenGeoExt = (classId) => {
  return sendRequest(`${apiUrl}/oedatasetcombos?classId=${classId}`, 'GET');
};

export const getOEScenGeoPrecision = (classId, geoExt) => {
  return sendRequest(`${apiUrl}/oedatasetcombos?classId=${classId}&geoExt=${geoExt}`, 'GET');
};

export const getOEScenGeoDatasetType = (classId, geoExt, geoPrecision) => {
  return sendRequest(
    `${apiUrl}/oedatasetcombos?classId=${classId}&geoExt=${geoExt}&geoPrecision=${geoPrecision}`,
    'GET'
  );
};

export const getOEScenGeoDataset = (classId, geoExt, geoPrecision, datasetType) => {
  return sendRequest(
    `${apiUrl}/oedatasetcombos?classId=${classId}&geoExt=${geoExt}&geoPrecision=${geoPrecision}&datasetType=${datasetType}`,
    'GET'
  );
};

//OLD DATASETS
export const getDataset = (dataSetId) => {
  return sendRequest(`${baseUrl}/datasets/${dataSetId}`, 'GET');
};

export const getDatasets = () => {
  return sendRequest(`${baseUrl}/v1/dataset`, 'GET');
};

export const postDataset = (dataset) => {
  return sendRequest(`${baseUrl}/v1/dataset`, 'POST', dataset);
};

export const putDataset = (dataset) => {
  return sendRequest(`${baseUrl}/v1/dataset/${dataset.id}`, 'PUT', dataset);
};

export const deleteDataset = (dataSetId) => {
  return sendRequest(`${baseUrl}/v1/dataset/${dataSetId}`, 'DELETE');
};

export const getExpenseItems = () => {
  return sendRequest(`${apiUrl}/expenseitems`, 'GET');
};

export const getExpenseItem = (itemId) => {
  return sendRequest(`${apiUrl}/expenseitems/${itemId}`, 'GET');
};

export const postExpenseItem = (data) => {
  return sendRequest(`${apiUrl}/expenseitems`, 'POST', data);
};

export const putExpenseItem = (data) => {
  return sendRequest(`${apiUrl}/expenseitems/${data.id}`, 'PUT', data);
};

export const deleteExpenseItem = (dataId) => {
  return sendRequest(`${apiUrl}/expenseitems/${dataId}`, 'DELETE');
};

//BRIDGE MATRIX
export const getBridgeMatrix = () => {
  return sendRequest(`${baseUrl}/bridgematrix`, 'GET');
};

export const postBridgeMatrix = (listId) => {
  return sendRequest(`${baseUrl}/bridgematrix`, 'POST', listId);
};

export const getExpenseItemsList = () => {
  return sendRequest(`${baseUrl}/expenseitemslist`, 'GET');
};

export const postExpenseItemsList = (data) => {
  return sendRequest(`${baseUrl}/expenseitemslist`, 'POST', data);
};

export const putExpenseItemsList = (data) => {
  return sendRequest(`${baseUrl}/expenseitemslist/${data.id}`, 'PUT', data);
};

export const copyExpenseItemsList = (data, dataId) => {
  return sendRequest(`${baseUrl}/expenseitemslist/clone/${dataId}`, 'POST', data);
};

export const deleteExpenseItemsList = (dataId) => {
  return sendRequest(`${baseUrl}/expenseitemslist/${dataId}`, 'DELETE');
};

// export const putRunScenario = (scenarioId) => {
//   return sendRequest(`${apiUrl}/scenarios/${scenarioId}/analyses`, 'PUT', { status: 'Executed' });
// };
export const putRunScenario = (scenarioId, variantId) => {
  return sendRequest(`${apiUrl}/scenarios/${scenarioId}/variants/${variantId}/analyses`, 'PUT', {
    status: 'Executed',
  });
};

// export const getRunScenarioStatus = (scenarioId) => {
//   return sendRequest(`${apiUrl}/scenarios/${scenarioId}?status=true`, 'GET');
// };
export const getRunScenarioStatus = (scenarioId, variantId) => {
  return sendRequest(`${apiUrl}/scenarios/${scenarioId}/variants/${variantId}?status=true`, 'GET');
};

export const getEconomicData = (scenarioId, variantId, analysisMode, regionCode) => {
  // convert analysisMode to lowercase
  // analysisMode = analysisMode.toLowerCase();
  analysisMode = analysisMode.toUpperCase();
  return sendRequest(
    `${apiUrl}/scenarios/${scenarioId}/variants/${variantId}/economicdata/${analysisMode}/${regionCode}`,
    'GET'
  );
};
// export const getEconomicData = (scenarioId, analysisMode, regionCode) => {
//   // convert analysisMode to lowercase
//   analysisMode = analysisMode.toLowerCase();
//   return sendRequest(
//     `${apiUrl}/scenarios/${scenarioId}/economicdata/${analysisMode}/${regionCode}`,
//     'GET'
//   );
// };

export const getUnemployment = () => {
  return sendRequest(`${apiUrl}/unemployment`, 'GET');
};

export const getUnemploymentDownload = () => {
  return sendRequest(
    `${apiUrl}/unemployment?format=xlsx`,
    'GET',
    null,
    getDownloadExcelHeaders,
    true,
    false
  );
};

export const uploadUnemployment = (formData) => {
  return sendRequest(`${apiUrl}/unemployment`, 'POST', formData, getUploadHeaders, false, true);
};

export const getIncomeTaxes = () => {
  return sendRequest(`${apiUrl}/incometaxes`, 'GET');
};

// export const getEconomicDataDownload = (scenarioId, analysisMode, regionCode) => {
//   analysisMode = analysisMode.toLowerCase();
//   return sendRequest(
//     `${apiUrl}/scenarios/${scenarioId}/economicdata/${analysisMode}/${regionCode}?format=xlsx`,
//     'GET'
//   );
// };
export const getEconomicDataDownload = (scenarioId, variantId, analysisMode, regionCode) => {
  // analysisMode = analysisMode.toLowerCase();
  analysisMode = analysisMode.toUpperCase();
  return sendRequest(
    `${apiUrl}/scenarios/${scenarioId}/variants/${variantId}/economicdata/${analysisMode}/${regionCode}?format=xlsx`,
    'GET'
  );
};

// export const getIncomeTaxesDownload = () => {
//   return sendRequest(`${apiUrl}/incometaxes?export=xlsx`, 'GET');
// };
export const getIncomeTaxesDownload = () => {
  return sendRequest(
    `${apiUrl}/incometaxes?format=xlsx`,
    'GET',
    null,
    getDownloadExcelHeaders,
    true,
    false
  );
};

export const uploadIncomeTaxes = (formData) => {
  return sendRequest(`${apiUrl}/incometaxes`, 'POST', formData, getUploadHeaders, false, true);
};

// export const getRegionKpiByScenario = (scenarioId) => {
//   return sendRequest(`${apiUrl}/scenario/${scenarioId}/kpis`, 'GET');
// };

export const getRegionKpiByScenario = (scenarioId, variantId) => {
  return sendRequest(`${apiUrl}/scenarios/${scenarioId}/variants/${variantId}/kpis`, 'GET');
};

// export const getRegionKpiByScenarioDownload = (scenarioId, regionCode) => {
//   return sendRequest(
//     `${apiUrl}/scenario/${scenarioId}/kpis/${regionCode}?format=xlsx`,
//     'GET',
//     false,
//     getDownloadExcelHeaders,
//     true
//   );
// };
export const getRegionKpiByScenarioDownload = (scenarioId, variantId, regionCode) => {
  return sendRequest(
    `${apiUrl}/scenarios/${scenarioId}/variants/${variantId}/kpis/${regionCode}?format=xlsx`,
    'GET',
    false,
    getDownloadExcelHeaders,
    true
  );
};

// export const postRegionKpiByScenarioUpload = (formData, scenarioId) => {
//   return sendRequest(
//     `${apiUrl}/scenario/${scenarioId}/kpis`,
//     'POST',
//     formData,
//     getUploadHeaders,
//     false,
//     true
//   );
// };
export const postRegionKpiByScenarioUpload = (formData, scenarioId, variantId) => {
  return sendRequest(
    `${apiUrl}/scenarios/${scenarioId}/variants/${variantId}/kpis`,
    'POST',
    formData,
    getUploadHeaders,
    false,
    true
  );
};

export const getShadowPrices = (datasetId) => {
  return sendRequest(`${apiUrl}/shadow-prices/${datasetId}`, 'GET');
};

export const getShadowPricesDownload = (datasetId) => {
  return sendRequest(
    `${apiUrl}/shadow-prices/${datasetId}?format=xlsx`,
    'GET',
    false,
    getDefaultHeaders,
    true
  );
};
// COUNTRY PROFILE
export const getOEIndicators = () => {
  return sendRequest(`${apiUrl}/oegeoindicators`, 'GET');
};

export const getFullDataIndicators = (regionCode) => {
  return sendRequest(
    `${apiUrl}/countryprofile/geoindicators/${regionCode}?source=world%20bank`,
    'GET'
  );
};

export const getMapIndicators = (year) => {
  return sendRequest(
    `${apiUrl}/countryprofile/geoindicators?year=${year}&source=world%20bank`,
    'GET'
  );
};

export const getScenarioVariants = (scenarioId) => {
  return sendRequest(`${apiUrl}/scenarios/${scenarioId}/variants`, 'GET');
};

export const getScenarioVariant = (scenarioId, variantId) => {
  return sendRequest(`${apiUrl}/scenarios/${scenarioId}/variants/${variantId}`, 'GET');
};

export const postScenarioVariant = (scenarioId, data) => {
  return sendRequest(`${apiUrl}/scenarios/${scenarioId}/variants`, 'POST', data);
};

export const putScenarioVariant = (scenarioId, variantId, data) => {
  return sendRequest(`${apiUrl}/scenarios/${scenarioId}/variants/${variantId}`, 'PUT', data);
};

export const deleteScenarioVariant = (scenarioId, variantId) => {
  return sendRequest(`${apiUrl}/scenarios/${scenarioId}/variants/${variantId}`, 'DELETE');
};

export const getScenarioMonitoringKpisList = (scenarioId) => {
  return sendRequest(`${apiUrl}/scenarios/${scenarioId}/monitoring/parameters`, 'GET');
};
export const getScenarioMonitoringYears = (scenarioId) => {
  return sendRequest(`${apiUrl}/scenarios/${scenarioId}/monitoring/years`, 'GET');
};
export const getScenarioMonitoringSroi = (scenarioId) => {
  return sendRequest(`${apiUrl}/scenarios/${scenarioId}/monitoring/sroi`, 'GET');
};
export const getScenarioMonitoring = (scenarioId, year, kpiId = null) => {
  return sendRequest(
    `${apiUrl}/scenarios/${scenarioId}/monitoring/kpis?year=${year}${
      kpiId ? `&kpiId=${kpiId}` : ''
    }`,
    'GET'
  );
};
// export const getEntityDocuments = (entityType, entityId) => {
//   return sendRequest(`${apiUrl}/documents/${entityType}/${entityId}`, 'GET');
// };
export const getEntityDocuments = (entityType, entityId, dependsId) => {
  const url = new URL(`${apiUrl}/documents/${entityType}/${entityId}`);

  if (dependsId) {
    url.searchParams.append('dependent', dependsId);
  }

  return sendRequest(url.toString(), 'GET');
};
export const downloadEntityDocuments = (documentId) => {
  return sendRequest(`${apiUrl}/documents/${documentId}`, 'GET', null, getDefaultHeaders, true);
};

export const uploadEntityDocument = (entityType, entityId, dependsId, formData) => {
  const url = new URL(`${apiUrl}/documents/${entityType}/${entityId}`);

  if (dependsId) {
    url.searchParams.append('dependent', dependsId);
  }

  return sendRequest(url.toString(), 'POST', formData, getUploadHeaders, false, true);
};

// export const uploadEntityDocument = (entityType, entityId, formData) => {
//   return sendRequest(
//     `${apiUrl}/documents/${entityType}/${entityId}`,
//     'POST',
//     formData,
//     getUploadHeaders,
//     false,
//     true
//   );
// };

// Questionario
export const uploadQuestionnaire = (classId, formData) => {
  return sendRequest(
    `${apiUrl}/classes/${classId}/questionnaires?format=xlsx`,
    'POST',
    formData,
    getUploadHeaders,
    false,
    true
  );
};

export const getQuestionnaire = (classId) => {
  return sendRequest(`${apiUrl}/classes/${classId}/questionnaires`, 'GET');
};
export const getQuestionnaireDownload = (classId) => {
  return sendRequest(
    `${apiUrl}/classes/${classId}/questionnaires?format=xlsx`,
    'GET',
    null,
    getDownloadExcelHeaders,
    true,
    false
  );
};

export const getCompaniesContracts = (companyId) => {
  return sendRequest(`${apiUrl}/companies/${companyId}/contracts`, 'GET');
};

export const postCompaniesContracts = (companyId, data) => {
  return sendRequest(`${apiUrl}/companies/${companyId}/contracts`, 'POST', data);
};

export const putCompaniesContracts = (companyId, contractId, data) => {
  return sendRequest(`${apiUrl}/companies/${companyId}/contracts/${contractId}`, 'PUT', data);
};

// ESG

export const getESGCategories = () => {
  return sendRequest(`${apiUrl}/oeesgcategories`, 'GET');
};
export const getESGSubCategories = () => {
  return sendRequest(`${apiUrl}/oeesgsubcategories`, 'GET');
};
export const getESGVariantQuestionnaire = (scenarioId, variantId) => {
  return sendRequest(
    `${apiUrl}/scenarios/${scenarioId}/variants/${variantId}/esgquestionnaire`,
    'GET'
  );
};
export const getESGVariantAnswers = (scenarioId, variantId) => {
  return sendRequest(
    `${apiUrl}/scenarios/${scenarioId}/variants/${variantId}/esgquestionnaire/answers`,
    'GET'
  );
};
export const getOEESGRatings = () => {
  return sendRequest(`${apiUrl}/oeratings`, 'GET');
};
export const getESGResults = (scenarioId, variantId) => {
  return sendRequest(`${apiUrl}/scenarios/${scenarioId}/variants/${variantId}/esg`, 'GET');
};

export const postESGVariantQuestionnaireAnswers = (scenarioId, variantId, data) => {
  return sendRequest(
    `${apiUrl}/scenarios/${scenarioId}/variants/${variantId}/esgquestionnaire`,
    'POST',
    data
  );
};

//Corporate Class
export const getCorporateClasses = () => {
  return sendRequest(`${apiUrl}/corporateclasses`, 'GET');
};

export const getCorporateClass = (corporateClassId) => {
  return sendRequest(`${apiUrl}/corporateclasses/${corporateClassId}`, 'GET');
};

export const postCorporateClass = (data) => {
  return sendRequest(`${apiUrl}/corporateclasses`, 'POST', data);
};

export const putCorporateClass = (data, corporateClassId) => {
  return sendRequest(`${apiUrl}/corporateclasses/${corporateClassId}`, 'PUT', data);
};

export const deleteCorporateClass = (corporateClassId) => {
  return sendRequest(`${apiUrl}/corporateclasses/${corporateClassId}`, 'DELETE');
};

// Corporate Class Questionnaire

export const getCorporateClassQuestionnaire = (corporateClassId) => {
  return sendRequest(
    `${apiUrl}/corporateclasses/${corporateClassId}/questionnaires/versions`,
    'GET'
  );
};

export const getCorporateClassQuestionnaireDownload = (corporateClassId) => {
  return sendRequest(
    `${apiUrl}/corporateclasses/${corporateClassId}/questionnaires?format=xlsx`,
    'GET',
    null,
    getDownloadExcelHeaders,
    true,
    false
  );
};

export const uploadCorporateClassQuestionnaire = (corporateClassId, formData) => {
  return sendRequest(
    `${apiUrl}/corporateclasses/${corporateClassId}/questionnaires?format=xlsx`,
    'POST',
    formData,
    getUploadHeaders,
    false,
    true
  );
};

// Corporate ESG

//Analyses
export const getCorporateEsgAnalysis = (companyId) => {
  return sendRequest(`${apiUrl}/companies/${companyId}/analyses`, 'GET');
};
export const postCorporateEsgAnalysis = (companyId, corporateClassId, data = { type: 'ESG' }) => {
  return sendRequest(
    `${apiUrl}/companies/${companyId}/corporateclasses/${corporateClassId}/esg`,
    'POST',
    data
  );
};
export const deleteCorporateEsgAnalysis = (companyId, analysisId) => {
  return sendRequest(`${apiUrl}/companies/${companyId}/analyses/${analysisId}/esg`, 'DELETE');
};

//General
export const getCompaniesLocations = (companyId) => {
  return sendRequest(`${apiUrl}/companies/${companyId}/locations`, 'GET');
};

export const postCompaniesLocations = (companyId, data) => {
  return sendRequest(`${apiUrl}/companies/${companyId}/locations`, 'POST', data);
};

export const putCompaniesLocations = (companyId, locationId, data) => {
  return sendRequest(`${apiUrl}/companies/${companyId}/locations/${locationId}`, 'PUT', data);
};

export const deleteCompaniesLocations = (companyId, locationId) => {
  return sendRequest(`${apiUrl}/companies/${companyId}/locations/${locationId}`, 'DELETE');
};

//KPIS
export const getCorporateAnalysesKpis = (companyId, analysisId) => {
  return sendRequest(`${apiUrl}/companies/${companyId}/analyses/${analysisId}/kpis`, 'GET');
};

export const getCorporateAnalysesKpisIndicators = (companyId, analysisId, kpiId) => {
  return sendRequest(
    `${apiUrl}/companies/${companyId}/analyses/${analysisId}/kpis/${kpiId}/indicators`,
    'GET'
  );
};
export const putCorporateAnalysesKpisIndicators = (
  companyId,
  analysisId,
  kpiId,
  indicatorId,
  data
) => {
  return sendRequest(
    `${apiUrl}/companies/${companyId}/analyses/${analysisId}/kpis/${kpiId}/indicators/${indicatorId}`,
    'PUT',
    data
  );
};

export const postCorporateAnalysesKpisCompute = (companyId, analysisId, kpiId) => {
  return sendRequest(
    `${apiUrl}/companies/${companyId}/analyses/${analysisId}/kpis/${kpiId}/compute`,
    'POST'
  );
};

//Questionnaire
export const getCorporateQuestionnaire = (companyId, analysysId) => {
  return sendRequest(
    `${apiUrl}/companies/${companyId}/analyses/${analysysId}/esgquestionnaire`,
    'GET'
  );
};

export const getCorporateQuestionnaireAnswers = (companyId, analysysId) => {
  return sendRequest(
    `${apiUrl}/companies/${companyId}/analyses/${analysysId}/esgquestionnaire/answers`,
    'GET'
  );
};

export const postCorporateQuestionnaireAnswers = (companyId, analysysId, data) => {
  return sendRequest(
    `${apiUrl}/companies/${companyId}/analyses/${analysysId}/esgquestionnaire`,
    'POST',
    data
  );
};

export const postCorporateEsgCalculation = (companyId, analysysId) => {
  return sendRequest(`${apiUrl}/companies/${companyId}/analyses/${analysysId}/esg`, 'POST');
};

export const getCorporateAnalysesResults = (companyId, analysysId) => {
  return sendRequest(`${apiUrl}/companies/${companyId}/analyses/${analysysId}/esg`, 'GET');
};

export const postCheckKpiFormula = (data) => {
  return sendRequest(`${apiUrl}/kpis/checkKpiFormula`, 'POST', data);
};

// export const getCorporateQuestionAttachment = (questionId) => {
//   return sendRequest(
//     `${apiUrl}/documents/Question/${questionId}`,
//     'GET',
//     null,
//     getDefaultHeaders,
//     true
//   );
// };
export const getCorporateAnalysesClasses = (companyId) => {
  return sendRequest(`${apiUrl}/companies/${companyId}/analyses/corporateclasses`, 'GET');
};

export const downloadAsBlob = (url) => {
  return fetch(`${url}`, {
    method: 'GET',
  })
    .then((res) => res.blob())
    .then((blob) => {
      return blob;
    });
  //return res
};

const api = {
  getBaseUrl,
  getOEScenarios,
  getOEScenario,
  postScenario,
  putScenario,
  deleteScenario,
  getScenari,
  getOpenScenari,
  getScenarioMeta,
  getScenario,
  getVisibilities,
  getScenarioTypologies,
  getFinInputTypes,
  getOECurrencies,
  getCurrencies,
  getAnalyses,
  postScenAnalysis,
  putScenAnalysis,
  getGeoExtensions,
  getGeoPrecisions,
  getDatasetTypes,
  putScenAdditionalInfo,
  deleteScenAdditionalInfo,
  getCountry,
  getCountryfm,
  getAdminRegion,
  getAdminSam,
  getAdminClasses,
  getScenarioSelect,
  getOELanguages,
  getTranslations,
  getDefaultHeaders,
  getAuthUser,
  getScenariDashboard,
  getScenariKpi,
  postScenariKpi,
  checkLogin,
  checkLoginOtp,
  forgotPassword,
  resetPassword,
  validateOtpForgotPassword,
  getUsers,
  getUser,
  postCreateUser,
  putEditUser,
  deleteUser,
  getRoles,
  getTypes,
  getSam,
  getAdminOrganization,
  getOrganizations,
  getOrganization,
  postCreateOrganization,
  putEditOrganization,
  deleteOrganization,
  getClasses,
  getClass,
  postCreateClass,
  putEditClass,
  deleteClass,
  copyClass,
  copyFullClass,
  postBridgeMatricesFromClass,
  getNaceSectors,
  getSectors,
  getInterventTypes,
  getTags,
  getKpis,
  getKpi,
  postCreateKpi,
  putEditKpi,
  deleteKpi,
  copyKpi,
  getOEKpis,
  getOEKpi,
  postCreateOEKpi,
  putEditOEKpi,
  deleteOEKpi,
  copyOEKpi,
  getTextTranslations,
  getTextTranslation,
  postCreateTranslation,
  putEditTranslation,
  deleteTranslation,
  getCountries,
  getOEDatasets,
  getOEGeoDatasets,
  getDatasetRegions,
  postFinancialData,
  deleteFinancialData,
  getFinancialData,
  getDataset,
  getDatasets,
  postDataset,
  putDataset,
  deleteDataset,
  getExpenseItems,
  getExpenseItem,
  postExpenseItem,
  putExpenseItem,
  deleteExpenseItem,
  getBridgeMatrix,
  postBridgeMatrix,
  getExpenseItemsList,
  postExpenseItemsList,
  putExpenseItemsList,
  copyExpenseItemsList,
  deleteExpenseItemsList,
  putRunScenario,
  getOEInterventionModules,
  putOEInterventionModules,
  getClassesVersions,
  getRunScenarioStatus,
  getMacroimpactScenari,
  downloadOEScenarioItem,
  downloadOEScenarioAdditionalInfo,
  cloneScenario,
  getOEScenGeoExt,
  getOEScenGeoPrecision,
  getOEScenGeoDatasetType,
  getOEScenGeoDataset,
  getClassesDistinct,
  getEconomicData,
  getEconomicDataDownload,
  getIncomeTaxes,
  getIncomeTaxesDownload,
  uploadIncomeTaxes,
  getRegionKpiByScenario,
  getRegionKpiByScenarioDownload,
  postRegionKpiByScenarioUpload,
  delScenAnalysis,
  getUnemploymentDownload,
  getUnemployment,
  uploadUnemployment,
  getShadowPrices,
  getShadowPricesDownload,
  getOEIndicators,
  getFullDataIndicators,
  getMapIndicators,
  downloadAsBlob,
  cloneOEDataset,
  getScenarioVariants,
  getScenarioVariant,
  postScenarioVariant,
  putScenarioVariant,
  deleteScenarioVariant,
  getScenarioMonitoring,
  getScenarioMonitoringKpisList,
  getScenarioMonitoringYears,
  getScenarioMonitoringSroi,
  getEntityDocuments,
  uploadEntityDocument,
  uploadQuestionnaire,
  getQuestionnaireDownload,
  downloadEntityDocuments,
  getCompaniesContracts,
  postCompaniesContracts,
  putCompaniesContracts,
  getESGVariantQuestionnaire,
  postESGVariantQuestionnaireAnswers,
  getESGVariantAnswers,
  getOEESGRatings,
  getESGResults,
  getCorporateClasses,
  getCorporateClass,
  postCorporateClass,
  putCorporateClass,
  deleteCorporateClass,
  getCorporateClassQuestionnaire,
  getCorporateClassQuestionnaireDownload,
  uploadCorporateClassQuestionnaire,
  getCorporateAnalysesKpis,
  getCorporateAnalysesKpisIndicators,
  putCorporateAnalysesKpisIndicators,
  postCorporateAnalysesKpisCompute,
  postCorporateEsgAnalysis,
  deleteCorporateEsgAnalysis,
  getCompaniesLocations,
  postCompaniesLocations,
  putCompaniesLocations,
  deleteCompaniesLocations,
  getCorporateEsgAnalysis,
  getCorporateQuestionnaire,
  getCorporateQuestionnaireAnswers,
  postCorporateQuestionnaireAnswers,
  postCorporateEsgCalculation,
  getCorporateAnalysesResults,
  postCheckKpiFormula,
  getCorporateAnalysesClasses,
  // getCorporateQuestionAttachment,
  // testCall,
  // checkTokenValidity,
};

export default api;
