import React from 'react';
import { Form } from 'react-bootstrap';
import QuestionText from './QuestionText';
import GenericDownloadUploadItems from 'components/DownloadUploadView/GenericDownloadUploadView';
import TooltipComponent from './TooltipComponent';
const InputCheckbox = ({ question, handleAssessmentChange, handleChecked }) => (
  <QuestionText question={question}>
    <div key={`default-checkbox-${question.id}`} className='mb-3'>
      {question.answers.map((answer) => {
        return (
          <div key={answer.id} className='d-flex align-items-center mb-3 row'>
            <div className='col d-flex align-items-center'>
              <Form.Check
                type='checkbox'
                // key={answer.id}
                id={`${answer.id}`}
                name={`${question.id}`}
                label={answer.text}
                onChange={(e) => handleAssessmentChange(e, question.id, answer.id, question.type)}
                checked={handleChecked(question.id, answer.id)}
                // className='mb-3'
              />
              {answer.description ? <TooltipComponent data={answer.description} /> : null}
            </div>
            {!!answer.attachment && (
              <div className='col'>
                <GenericDownloadUploadItems
                  title={'Allegato'}
                  disabled={!handleChecked(question.id, answer.id)}
                  corporatequestionnaireId={question?.corporatequestionnaireId || null}
                  answerId={answer.id}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  </QuestionText>
);

export default InputCheckbox;
